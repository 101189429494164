import {
  GET_DETAILS_BY_ADMIN,
  GET_DETAILS_BY_ADMIN_SUCCESS,
  GET_DETAILS_BY_ADMIN_FAIL,
  DETAILS_BY_ADMIN_UPDATE,
  DETAILS_BY_ADMIN_UPDATE_FAIL,
  DETAILS_BY_ADMIN_UPDATE_SUCCESS,
  GET_TABLENAMES,
  GET_TABLENAMES_SUCCESS,
  GET_TABLENAMES_FAIL,
} from "./actionTypes";

const initialState = {
  systemAdminList: [],
  tableList: [],
  process: {},
  processStatusList: [],
  postCreated: {},
  postingResult: {},
  loading: false,
  loadingProcessDetails: false,
  processSetting: {},
  loadingProcessSetting: false,
  posting: false,
  error: {
    message: "",
  },
};

const PostReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_DETAILS_BY_ADMIN:
      state = { ...state, loading: true };
      break;
    case GET_DETAILS_BY_ADMIN_SUCCESS:
      state = {
        ...state,
        systemAdminList: action.payload,
        loading: false,
      };
      break;
    case GET_DETAILS_BY_ADMIN_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_TABLENAMES:
      state = { ...state };
      break;
    case GET_TABLENAMES_SUCCESS:
      state = {
        ...state,
        tableList: action.payload,
      };
      break;
    case GET_TABLENAMES_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
      };
      break;
    case DETAILS_BY_ADMIN_UPDATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case DETAILS_BY_ADMIN_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case DETAILS_BY_ADMIN_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostReducer;
