import {
    GET_AUDIT_LOGS,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAIL,
    RESET,
} from "./actionsTypes";

const initialState = {
    auditLogs: [],
    auditLogsLoading: false,
    error: {
        message: "",
    },
};

const AuditLogsReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case GET_AUDIT_LOGS:
            state = { ...state, auditLogsLoading: true, auditLogs: [] };
            break;
        case GET_AUDIT_LOGS_SUCCESS:
            state = { ...state, auditLogs: action.payload, auditLogsLoading: false };
            break;
        case GET_AUDIT_LOGS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                auditLogsLoading: false,
            };
            break;

        case RESET:
            state = { ...initialState };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AuditLogsReducer;