import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import * as Yup from "yup";

import { Breadcrumbs, Btn } from "../../AbstractElements";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBroom,
  faCopy,
  faFaceSmile,
  faFilePen,
} from "@fortawesome/pro-duotone-svg-icons";
import { isAllowedURL } from "../../_helper/helper";
import { Link, useNavigate } from "react-router-dom";
import CustomLegand from "../Create Transaction/CustomLegand";
import { ExpandMore } from "@mui/icons-material";
import CustomTextField from "../../Layout/Components/TextField";
import { useFormik } from "formik";
import ConfirmModal from "./ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGGTrackData } from "../Create Transaction/slices/ggTrackSlice";
import axios from "axios";

const genderArray = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
];
const maritalArray = [
  {
    name: "Single",
    value: "single",
  },
  {
    name: "Married",
    value: "married",
  },
];

const CreateEmployeeGG1 = () => {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [DPDVCode, setDPDVCode] = useState([]);
  const [DPDVName, setDPDVName] = useState([]);

  const [schema, setPerformanceSchema] = useState({
    ssn: Yup.string()
      .max(15, "SSN value must be less than 15 digits.")
      .required("SSN is Required"),
    email: Yup.string().email().required("Email is Required"),
    hmAddLn1: Yup.string()
      // .min(5, "must be 5 character")
      .required("Home address is requierd"),
    mlAddLn1: Yup.string()
      // .min(5, "must be 5 character")
      .required("Mailing address is requierd"),
    hmAddState: Yup.string()
      .max(2, "Maximum 2 character")
      .required("Home State Code is requierd"),
    mlAddState: Yup.string()
      .max(2, "Maximum 2 character")
      .required("Mailing State Code is requierd"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchGGTrackData());
  }, []);

  const lookupItems = useSelector((state) => state.ggTrackSlice.GGTrackData);

  const initialValues = {
    id: 0,
    employeeNameFml: null,
    employeeNameLfm: null,
    employeeName: null,
    dpdv: null,
    ssn: params?.ssn || null,
    firstName: null,
    middleName: null,
    lastName: null,
    budgePosNo: null,
    npositionNo: null,
    physicalExam: null,
    inactiveStatus: null,
    ndateOfBirth: null,
    maritalStatus: null,
    emplType: null,
    empStatus: null,
    natureOfAction: null,
    ethnicGroup: null,
    sex: null,
    militaryStatus: null,
    veteran: null,
    education: null,
    handicapped: null,
    handiCond: null,
    preferencePts: null,
    i9: null,
    citizen: null,
    hmAddLn1: null,
    hmAddCity: null,
    hmAddState: null,
    hmAddZip: null,
    hmPhone: null,
    mlAddLn1: null,
    mlAddCity: null,
    mlAddState: null,
    mlAddZip: null,
    mlWkPhone: null,
    probation: null,
    deptNo: null,
    collar: null,
    rangeActual: null,
    rangePay: null,
    stepActual: null,
    annualSalary: null,
    rate: null,
    performancePts: null,
    reemployDate: null,
    servPrior: null,
    dateTerm: null,
    ndateIncrem: null,
    dateEmployment: null,
    courtClrDate: null,
    policeClrDate: null,
    retireSsCode: null,
    driversLicenseNo: null,
    ninsHealthType: null,
    ninsDentType: null,
    ninsLifeType: null,
    name2: null,
    jacketNo: null,
    jktPrintedFlag: null,
    postCurrentDate: null,
    dtlAddtlRate: null,
    dtlTermDate: null,
    newEmployee: null,
    processedDate: null,
    workSch: null,
    lastActnDate: null,
    requestId: null,
    appropType: null,
    email: null,
    npositionTitle: null,
    educationTitle: null,
    inactiveStatusTitle: null,
    empStatusTitle: null,
    emplTypeTitle: null,
    ethnicGroupTitle: null,
    departmentTitle: null,
    employeeType: null,
    fteno: null,
  };
  const onSubmit = async (e) => {
    setOpen(true);

    console.log("dsadadadad", e);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    validateForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape(schema),
    onSubmit,
  });
  // console.log(
  //   "121212 lookupItems ::: ",
  //   lookupItems,
  //   values.dpdv,
  //   values.departmentTitle
  // );
  useEffect(() => {
    if (values?.departmentTitle) {
      let dpdvData = lookupItems?.dpdvItems?.find(
        (i) => i.label == values?.departmentTitle
      );
      setFieldValue("dpdv", dpdvData?.value);
      setFieldValue("departmentTitle", dpdvData?.label);
      setFieldValue("deptNo", dpdvData?.deptNo);
    }
  }, [values?.departmentTitle]);
  useEffect(() => {
    if (values?.dpdv) {
      let dpdvData = lookupItems?.dpdvItems?.find(
        (i) => i.value == values?.dpdv
      );
      setFieldValue("dpdv", dpdvData?.value);
      setFieldValue("departmentTitle", dpdvData?.label);
      setFieldValue("deptNo", dpdvData?.deptNo);
    }
  }, [values?.dpdv]);

  function renderTextInput(
    stateName,
    label,
    disabled = false,
    multiline = false,
    required = false,
    shrink = true
  ) {
    return (
      <TextField
        fullWidth
        size="small"
        inputProps={{
          style: {
            fontSize: "14px",
            paddingTop: "12px",
            width: "100%",
          },
          readOnly: disabled,
        }}
        margin="dense"
        variant="outlined"
        label={label}
        disabled={disabled}
        name={stateName}
        id={stateName}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        value={values[stateName]}
        InputLabelProps={{ shrink: shrink ? shrink : false }}
        shrink={shrink ? shrink : false}
        onChange={handleChange}
        // onBlur={handleBlur}
        error={touched[stateName] && errors[stateName]}
        helperText={touched[stateName] && errors[stateName]}
        required={required}
      />
    );
  }

  function renderDropDown(stateName, label, value, disabled) {
    return (
      <div>
        <TextField
          margin="dense"
          className="flex w-full  mt-2"
          variant="outlined"
          select
          SelectProps={{ native: true }}
          style={{ width: "100%" }}
          size="small"
          disabled={disabled}
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label={label}
          name={stateName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          error={errors[stateName]}
          helperText={errors[stateName]}
          value={values[stateName]}
        >
          {/* <option value={""} disabled> */}
          {/* value={values[stateName]}> */}
          <option value={""}>Select {label}</option>

          {value.length &&
            value.map((option, index) => (
              <option key={`${index}-${option.value}`} value={option.value}>
                {option.name}
              </option>
            ))}
        </TextField>
      </div>
    );
  }

  function renderDateInput(
    stateName,
    label,
    disabled = false
    // minDate,
    // maxDate
  ) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputVariant={"outlined"}
          margin="dense"
          format="MM/dd/yyyy"
          label={label}
          // minDate={minDate}
          // maxDate={maxDate}
          value={values[stateName]}
          KeyboardButtonProps={{ "aria-label": label }}
          disabled={disabled}
          onChange={(value) => {
            const formatDate = value?.$d;
            const date = formatDate
              ? moment(formatDate).format("YYYY-MM-DD hh:mm:ss")
              : null;
            setFieldValue(stateName, date);
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin="dense"
              size="small"
              InputLabelProps={{ shrink: true }}
              {...params}
              error={!disabled && errors[stateName]}
              helperText={!disabled && errors[stateName]}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
  const [transactionView, setTransactionView] = useState(true);
  const [transactionView1, setTransactionView1] = useState(true);
  const [transactionView2, setTransactionView2] = useState(true);

  useEffect(() => {
    if (lookupItems?.dpdvItems) {
      let dpdvName = lookupItems.dpdvItems?.map((i) => {
        return { name: i.label, value: i.label };
      });
      let dpdvCode = lookupItems.dpdvItems?.map((i) => {
        return { name: i.value, value: i.value };
      });

      setDPDVCode(dpdvCode);
      setDPDVName(dpdvName);
    }
  }, [lookupItems.dpdvItems]);

  console.log("123123 positionsas", values?.npositionNo);

  useEffect(async () => {
    if (values?.npositionNo) {
      const nPositionOBJ = lookupItems?.positionTitle.find(
        (i) => i.value == values?.npositionNo
      );
      setFieldValue("npositionTitle", nPositionOBJ?.label);
      //API for getting salary and rate
      try {
        let data = await axios.get(
          `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/GetPositionSalaryByGrade/${nPositionOBJ?.range_next}/${nPositionOBJ?.label}`, //543015044
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (data?.data?.length) {
          const firstStep = data?.data?.find((i) => i.step == "01");
          console.log("1212 Data :::fsdfs ", firstStep);
          setFieldValue("stepActual", firstStep?.step);
          setFieldValue("annualSalary", firstStep?.salary);
          setFieldValue("rangePay", firstStep?.grade);
          setFieldValue("rate", firstStep?.rate);
        }
        // if(console.error();)
      } catch (err) {
        console.log("1212 Err ::: ", err.response);
      }

      console.log("nPositionOBJ", nPositionOBJ);
    }
  }, [values?.npositionNo]);

  const saveData = async () => {
    try {
      let data = await axios.post(
        `${process.env.REACT_APP_API_DOTNET_URL}/Employee/AddGG1EmployeeMaster`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );

      // if(console.error();)
      console.log("finaL DATA SAVED", data);
      if (data?.data) {
        navigate(`/gg1-tracking/new-request/${values?.ssn}`);
      }
    } catch (err) {
      console.log("finaL DATA SAVED err", err);
    }
  };

  useEffect(() => {
    if (values?.dpdv) {
      const dpdvNo = lookupItems?.dpdvItems?.find(
        (i) => i.value == values?.dpdv
      );
      console.log("dpdvNore", dpdvNo);
    }
  }, [values?.dpdv]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs
          mainTitle={
            <Typography variant="h5">
              <i class="fa-duotone fa-list-ol m-r-10"></i>
              Create New Employee Master
            </Typography>
          }
          parent={
            isAllowedURL("/employee-management") ? (
              <Link
                to={`${process.env.PUBLIC_URL}/employee-management`}
                className="font-light"
              >
                Employee Managment
              </Link>
            ) : isAllowedURL("/transactionlist") ? (
              <Link
                to={`${process.env.PUBLIC_URL}/gg1-tracking`}
                className="font-light"
              >
                GG1 Tracking
              </Link>
            ) : undefined
          }
          title={"Employee Create"}
        />
        <CardHeader>
          <div
            className={` w-100`}
            style={{
              height: "3.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <div
              className="mt-2 mr-3   d-flex justify-content-end bd-highlight "
              style={{ paddingRight: "3rem", paddingBottom: "1rem" }}
            >
              {" "}
              <div className="mr-2">
                <Button
                  color="primary"
                  onClick={(e) => {
                    // e.preventDefault();
                    handleSubmit();
                  }}
                  // type: "submit",
                  style={{
                    marginRight: "4px",
                    marginBottom: "5px",
                  }}
                >
                  <>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFilePen}
                      style={{
                        color: "white",
                        marginRight: "5px",
                      }}
                    />
                    Save
                  </>
                </Button>
              </div>
            </div>{" "}
          </div>
        </CardHeader>
        <Row>
          <Col
            sm="12"
            style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}
          >
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end mb-2 ">
                  {/* <CustomLegand /> */}
                </div>
                <Accordion expanded={transactionView}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView(!transactionView)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Employee Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("ssn", "Social Security No.")}
                      </Col>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput("ndateOfBirth", "Date of Birth")}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("lastName", "Last Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("firstName", "First Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("middleName", "Middle Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown("sex", "Sex", lookupItems?.genderItems)}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("email", "Email Address")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmPhone", "Home Phone Number")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlWkPhone", "Work Phone Number")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "maritalStatus",
                          "Merital Status",
                          lookupItems?.maritalStatusItems
                        )}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={transactionView1}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView1(!transactionView1)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Address Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddLn1", "Home Address Street")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddCity", "City")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddState", "State")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddZip", "Zipcode")}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddLn1", "Mailing Address Street")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddCity", "City")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddState", "State")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddZip", "Zipcode")}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={transactionView2}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView2(!transactionView2)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Other Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput(
                          "driversLicenseNo",
                          "Driver's License No."
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput(
                          "policeClrDate",
                          "Police Clearance Date"
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput(
                          "courtClrDate",
                          "Court Clearance Date"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "npositionNo",
                          "Position",
                          lookupItems?.positionTitle
                        )}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "departmentTitle",
                          "Department",
                          DPDVName
                        )}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown("dpdv", "Section/Division", DPDVCode)}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("rangePay", "Pay Grade")}
                      </Col>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("annualSalary", "Salary Per Annum")}
                      </Col>
                      {/* <Col md={5} lg={5} xs={12} style={{ padding: "10px" }}>
                          {renderTextInput("aa", "Section/Division")}
                        </Col> */}
                    </Row>
                    <Row>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("rate", "Rate Per Hour")}
                      </Col>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("fteno", "FTE No.")}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confimClick={saveData}
        message={"Please review before continuing."}
        // modalHeader="Alert Message"
      />
    </Fragment>
  );
};

export default CreateEmployeeGG1;
