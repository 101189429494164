import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "GGTrackSlice";
export const fetchGGTrackData = createAsyncThunk(
  `${sliceName}/fetchGGTrackData`,
  async (actnNo) => {
    try {
      //get DPDV

      //get nature of transactions
      let NatureList = [];

      //get nature of transactions
      let PositionTitleList = [];
      let salaryItemsList = [];
      let dpdvItems = [];
      let processStatusItems = [];
      let evalTypeItems = [];
      let maritalStatusItems = [];
      let genderItems = [];
      try {
        const LookUpItems = await axios.get(
          `${process.env.REACT_APP_API_DOTNET_URL}/GG1/lookupitems?ActnNo=${actnNo}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (LookUpItems) {
          console.log("lookup", LookUpItems.data);
          LookUpItems.data.dpdvItems.map((item) => {
            dpdvItems.push({
              value: item.key,
              label: item.value,
              deptNo: item.deptNo,
              deptDiv: item.deptDiv,
            });
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });

          LookUpItems.data.natureItems.map((item) => {
            NatureList.push({
              value: item.key,
              label: item.value,
              authority: item.authority,
            });
          });
          LookUpItems.data.evalTypeItems.map((item) => {
            evalTypeItems.push({
              value: item.key,
              label: item.value,
            });
          });
          LookUpItems.data.salaryItems.map((item) => {
            salaryItemsList.push({
              grade: item.grade,
              step: item.step,
              nrate: item.nrate,
              nSalary: item.nSalary,
            });
          });
          LookUpItems.data.positionItems.map((item) => {
            PositionTitleList.push({
              value: item.key,
              label: item.value,
              name: item.value,
              range_next: item.range_next,
              titleWithGrade: item.titleWithGrade,
            });
          });
          LookUpItems.data.processStatusItems.map((item) => {
            processStatusItems.push({
              value: item.key,
              label: item.value,
            });
          });
          LookUpItems.data.maritalStatus.map((item) => {
            maritalStatusItems.push({
              value: item.key,
              label: item.description,
              name: item.description,
            });
          });
          LookUpItems.data.genderItems.map((item) => {
            genderItems.push({
              value: item.key,
              label: item.sex,
              name: item.sex,
            });
          });
        }
      } catch (error) {
        console.log("error", error);
      }

      let ggTrackArray = {
        nature: NatureList,
        positionTitle: PositionTitleList,
        dpdvItems: dpdvItems,
        salaryItemsList: salaryItemsList,
        processStatusItems: processStatusItems,
        evalTypeItems: evalTypeItems,
        maritalStatusItems: maritalStatusItems,
        genderItems: genderItems,
      };
      return {
        GGTrackData: ggTrackArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const fetchEthanicity = createAsyncThunk(
  `${sliceName}/fetchEthanicityData`,
  async (actnNo) => {
    try {
      //get DPDV

      //get nature of transactions

      let EthanicityItems = [];
      try {
        const fetchEthanicityItems = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "LK_Ethnicity",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: " ID desc",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (fetchEthanicityItems) {
          console.log("lookup", fetchEthanicityItems.data);

          // LookUpItems.data.genderItems.map((item) => {
          //   genderItems.push({
          //     value: item.key,
          //     label: item.sex,
          //     name: item.sex,
          //   });
          // });
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        EthanicityItems: EthanicityItems,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
const GGTrackSlice = createSlice({
  name: sliceName,
  initialState: {
    GGTrackData: {
      nature: [],
      positionTitle: [],
      dpdvItems: [],
      salaryItemsList: [],
      evalTypeItems: [],
      processStatusItems: [],
      maritalStatusItems: [],
      genderItems: [],
    },
  },
  extraReducers: {
    [fetchGGTrackData.fulfilled]: (state, action) => {
      console.log("action ::::: ==> ", action);
      state.GGTrackData = action.payload.GGTrackData;
    },
  },
});

export default GGTrackSlice.reducer;
