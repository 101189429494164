import { takeLatest, put, call } from "redux-saga/effects";
import { GET_AUDIT_LOGS, RESET } from "./actionsTypes";

import {
    getAuditLogsFail,
    getAuditLogsSuccess,
    reset,
} from "./action";

import {
    getAuditLogs
} from "../../_helper/services/AuditLogs";

function* onGetAuditLogs({ payload }) {
    try {
        const response = yield call(getAuditLogs, payload, payload?.location);
        yield put(getAuditLogsSuccess(response?.results));
    } catch (error) {
        yield put(getAuditLogsFail(error.response));
    }
}

function* TrainingLogSaga() {
    yield takeLatest(GET_AUDIT_LOGS, onGetAuditLogs);

    yield takeLatest(RESET, reset)
}

export default TrainingLogSaga;