import {
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_SUCCESS,
  ADVANCE_SEARCH_FAIL,
  LOAD_COLUMNS,
  LOAD_COLUMNS_SUCCESS,
  LOAD_COLUMNS_FAIL,
  FINDASYOUTYPE,
  FINDASYOUTYPE_SUCCESS,
  FINDASYOUTYPE_FAIL,
  SAVE_ADVANCE_SEARCH,
  SAVE_ADVANCE_SEARCH_SUCCESS,
  SAVE_ADVANCE_SEARCH_FAIL,
  GET_ADVANCE_SEARCH_BYID,
  GET_ADVANCE_SEARCH_BYID_SUCCESS,
  GET_ADVANCE_SEARCH_BYID_FAIL,
  GET_ALL_ADVANCE_SEARCH,
  GET_ALL_ADVANCE_SEARCH_SUCCESS,
  GET_ALL_ADVANCE_SEARCH_FAIL,
  SAVE_QUERY,
  SAVE_LOCATION,
  GG1_EXCEL_REPORT,
  GG1_EXCEL_REPORT_SUCCESS,
  GG1_EXCEL_REPORT_FAIL,
} from "./actionTypes";

const initialState = {
  searchResults: {},
  savedQuery: [],
  savedLocation: "",
  columns: [],
  empTypes: [],
  employee: {},
  findAsyouTypeResults: [],
  loadingFindAsYouType: false,
  loadingSearch: false,
  loadingColumns: false,
  loadingDeductionPlans: false,
  loadingEmployeeTypes: false,
  loadingEmployee: false,
  loadingPerformSubscription: false,
  lodingSaveType: "",
  getAdavanceSearch: [],
  getAdavanceSearchType: "",
  userSavedQueries: [],
  GG1ExcelReportResult: {},
  loadingExcelReport: false,
  error: {
    message: "",
  },
};

const AdvanceSearchReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case ADVANCE_SEARCH:
      state = { ...state, loadingSearch: true };
      break;
    case ADVANCE_SEARCH_SUCCESS:
      state = { ...state, searchResults: action.payload, loadingSearch: false };
      break;

    case ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
      };
      break;
    case GG1_EXCEL_REPORT:
      state = { ...state, loadingExcelReport: true };
      break;
    case GG1_EXCEL_REPORT_SUCCESS:
      state = {
        ...state,
        GG1ExcelReport: action.payload,
        loadingExcelReport: false,
      };
      break;
    case GG1_EXCEL_REPORT_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingExcelReport: false,
      };
      break;
    case SAVE_QUERY:
      state = { ...state, savedQuery: [...action.payload] };
      break;
    case SAVE_LOCATION:
      state = { ...state, savedLocation: action.payload };
      break;

    case SAVE_ADVANCE_SEARCH:
      state = { ...state, lodingSaveType: action.type };
      break;
    case SAVE_ADVANCE_SEARCH_SUCCESS:
      state = {
        ...state,
        searchResults: action.payload,
        lodingSaveType: action.type,
      };
      break;
    case SAVE_ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        lodingSaveType: action.type,
      };
      break;

    case GET_ADVANCE_SEARCH_BYID:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: action.type,
      };
      break;
    case GET_ADVANCE_SEARCH_BYID_SUCCESS:
      state = {
        ...state,
        getAdavanceSearch: action.payload,
        getAdavanceSearchType: action.type,
      };
      break;
    case GET_ADVANCE_SEARCH_BYID_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        getAdavanceSearch: [],
        getAdavanceSearchType: action.type,
      };
      break;

    case GET_ALL_ADVANCE_SEARCH:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        userSavedQueries: [],
      };
      break;

    case GET_ALL_ADVANCE_SEARCH_SUCCESS:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        userSavedQueries: action.payload,
      };
      break;
    case GET_ALL_ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        userSavedQueries: [],
      };
      break;

    case LOAD_COLUMNS:
      state = { ...state, loadingColumns: true };
      break;
    case LOAD_COLUMNS_SUCCESS:
      state = { ...state, columns: action.payload, loadingColumns: false };
      break;
    case LOAD_COLUMNS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingColumns: false,
      };
      break;
    case FINDASYOUTYPE:
      state = {
        ...state,
        findAsyouTypeResults: [],
        loadingFindAsYouType: true,
      };
      break;
    case FINDASYOUTYPE_SUCCESS: {
      state = {
        ...state,
        findAsyouTypeResults: action.payload,
        loadingFindAsYouType: false,
      };
      break;
    }
    case FINDASYOUTYPE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingFindAsYouType: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AdvanceSearchReducer;
