import {
	ADVANCE_SEARCH,
	ADVANCE_SEARCH_SUCCESS,
	ADVANCE_SEARCH_FAIL,
	LOAD_COLUMNS,
	LOAD_COLUMNS_SUCCESS,
	LOAD_COLUMNS_FAIL,
	GET_EMPLOYEE_DEDUCTION,
	GET_EMPLOYEE_DEDUCTION_SUCCESS,
	GET_EMPLOYEE_DEDUCTION_FAIL,
	GET_EMPLOYEE_TYPES,
	GET_EMPLOYEE_TYPES_FAIL,
	GET_EMPLOYEE_TYPES_SUCCESS,
	GET_EMPLOYEE,
	GET_EMPLOYEE_SUCCESS,
	GET_EMPLOYEE_FAIL,
	SUBSCRIBE_DEDUCTION,
	SUBSCRIBE_DEDUCTION_SUCCESS,
	SUBSCRIBE_DEDUCTION_FAIL,
	UNSUBSCRIBE_DEDUCTION,
	UNSUBSCRIBE_DEDUCTION_SUCCESS,
	UNSUBSCRIBE_DEDUCTION_FAIL,
	FINDASYOUTYPE,
	FINDASYOUTYPE_SUCCESS,
	FINDASYOUTYPE_FAIL
  } from "./actionTypes";
  
  const initialState = {
	searchResults: [],
	columns: [],
	empTypes: [],
	employee: {},
	findAsyouTypeResults:[],
	loadingFindAsYouType: false,
	loadingSearch: false,
	loadingColumns: false,
	loadingDeductionPlans: false,
	loadingEmployeeTypes: false,
	loadingEmployee: false,
	loadingPerformSubscription: false,
	error: {
	  message: "",
	},
  };
  
  const EmployeeReducer = (state = initialState, action) => {
	if(!action)
		return state;
	switch (action.type) {
	  case ADVANCE_SEARCH:
		state = { ...state, loadingSearch: true };
		break;
	  case ADVANCE_SEARCH_SUCCESS:
		state = { ...state, searchResults: action.payload, loadingSearch: false };
		break;
	  case ADVANCE_SEARCH_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingSearch: false,
		};
		break;
		case LOAD_COLUMNS:
			state = { ...state, loadingColumns: true };
			break;
		  case LOAD_COLUMNS_SUCCESS:
			state = { ...state, columns: action.payload, loadingColumns: false };
			break;
		  case LOAD_COLUMNS_FAIL:
			state = {
			  ...state,
			  error: {
				message: "Error",
			  },
			  loadingColumns: false,
			};
			break;
	  case GET_EMPLOYEE_DEDUCTION:
		state = { ...state, loadingDeductionPlans: true };
		break;
	  case GET_EMPLOYEE_DEDUCTION_SUCCESS:
		state = { ...state, empDuductionPlans: action.payload, loadingDeductionPlans: false };
		break;
	  case GET_EMPLOYEE_DEDUCTION_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingDeductionPlans: false,
		};
		break;
	case GET_EMPLOYEE_TYPES:
		state = { ...state, loadingEmployeeTypes: true };
		break;
	  case GET_EMPLOYEE_TYPES_SUCCESS:
		state = { ...state, empTypes: action.payload, loadingEmployeeTypes: false };
		break;
	  case GET_EMPLOYEE_TYPES_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingDeductionPlans: false,
		};
		break;
		case GET_EMPLOYEE:
			state = { ...state, loadingEmployee: true };
			break;
		  case GET_EMPLOYEE_SUCCESS:
			state = { ...state, employee: action.payload[0], loadingEmployee: false };
			break;
		  case GET_EMPLOYEE_FAIL:
			state = {
			  ...state,
			  error: {
				message: "Error",
			  },
			  loadingEmployee: false,
			};
			break;
			case SUBSCRIBE_DEDUCTION:
				state = { ...state, loadingPerformSubscription: true };
				break;
			  case SUBSCRIBE_DEDUCTION_SUCCESS:
				state = { ...state, employee: action.payload[0], loadingPerformSubscription: false };
				break;
			  case SUBSCRIBE_DEDUCTION_FAIL:
				state = {
				  ...state,
				  error: {
					message: "Error",
				  },
				  loadingPerformSubscription: false,
				};
				break;
				case UNSUBSCRIBE_DEDUCTION:
					state = { ...state, loadingPerformSubscription: true };
					break;
				  case UNSUBSCRIBE_DEDUCTION_SUCCESS:
					state = { ...state, employee: action.payload[0], loadingPerformSubscription: false };
					break;
				  case UNSUBSCRIBE_DEDUCTION_FAIL:
					state = {
					  ...state,
					  error: {
						message: "Error",
					  },
					  loadingPerformSubscription: false,
					};
					break;
					case FINDASYOUTYPE:
						state = { ...state, findAsyouTypeResults:[],loadingFindAsYouType: true };
						break;
					  case FINDASYOUTYPE_SUCCESS:
						{
							state = { ...state, findAsyouTypeResults: action.payload, loadingFindAsYouType: false };
							break;
						}
					  case FINDASYOUTYPE_FAIL:
						state = {
						  ...state,
						  error: {
							message: "Error",
						  },
						  loadingFindAsYouType: false,
						};
						break;
	  default:
		state = { ...state };
		break;
	}
	return state;
  };
  
  export default EmployeeReducer;