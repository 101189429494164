import axios from "axios";
// import { toast } from "react-toastify";
//apply base url for axios
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const axiosApi = axios.create({
  baseURL: REACT_APP_API_URL,
});
axiosApi.defaults.headers.common["x-access-token"] =
  localStorage.getItem("jwt_access_token") || "";
axiosApi.defaults.headers.common["Authorization"] = `Bearer ${
  localStorage.getItem("jwt_access_token") || ""
}`;
export const setApiToken = (token) => {
  axiosApi.defaults.headers.common["x-access-token"] = token;
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

axios.interceptors.request.use(function (config) {
  // const d = store.getState().session;
  if (config.headers.common) {
    config.headers.common["x-access-token"] =
      localStorage.getItem("jwt_access_token");
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "jwt_access_token"
    )}`;
  }

  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}
export async function post(url, payload, config) {
  return await axiosApi
    .post(url, payload, {
      ...config,
    })
    .then((response) => response.data);
}
export async function postReturnResponse(url, payload, config) {
  // debugger;
  return await axiosApi
    .post(url, payload, {
      ...config,
    })
    .then((response) => response);
}
export async function put(url, payload, config) {
  return await axiosApi
    .put(url, payload, {
      ...config,
    })
    .then((response) => response.data);
}
export async function remove(url, config) {
  return await axiosApi
    .delete(url, {
      ...config,
    })
    .then((response) => response);
}
