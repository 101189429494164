import {
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAIL,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FIELDS,
  GET_DOCUMENT_FIELDS_SUCCESS,
  GET_DOCUMENT_SOURCES,
  GET_DOCUMENT_SOURCES_SUCCESS,
  POST_ALL_DOCUMENTS,
  POST_ALL_DOCUMENTS_SUCCESS,
  POST_ALL_DOCUMENTS_FAIL,
  GET_DOCUMENT_FIELDS_FAIL,
  GET_DOCUMENT_SOURCES_FAIL,
  SET_REFERENCEID,
  POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS
} from "./actionTypes";

export const getDocumentSources = () => {
  return {
    type: GET_DOCUMENT_SOURCES,
  };
};
export const getDocumentSourcesSuccess = (result) => {
  return {
    type: GET_DOCUMENT_SOURCES_SUCCESS,
    payload: result,
  };
};

export const getDocumentSourcesFail = (error) => {
  return {
    type: GET_DOCUMENT_SOURCES_FAIL,
    payload: error,
  };
};

export const getDocumentFields = (payload) => {
  return {
    type: GET_DOCUMENT_FIELDS,
    payload: payload,
  };
};

export const getDocumentFieldsFail = (error) => {
  return {
    type: GET_DOCUMENT_FIELDS_FAIL,
    payload: error,
  };
};

export const getDocumentFieldsSuccess = (result) => {
  return {
    type: GET_DOCUMENT_FIELDS_SUCCESS,
    payload: result,
  };
};

export const postDocuments = (payload) => {
  return {
    type: POST_ALL_DOCUMENTS,
    payload: payload,
  };
};

export const postDocumentsFail = (error) => {
  return {
    type: POST_ALL_DOCUMENTS_FAIL,
    payload: error,
  };
};

export const setReferenceId = (payload) => {
  return {
    type: SET_REFERENCEID,
    payload: payload,
  };
};

export const postDocumentsSuccess = (result) => {
  return {
    type: POST_ALL_DOCUMENTS_SUCCESS,
    payload: result,
  };
};
export const postDocumentsHideLoaderSuccess = (result) => {
  return {
    type: POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS,
    payload: result,
  };
};

export const deleteDocument = (payload) => {
  console.log("payload", payload);
  return {
    type: DELETE_DOCUMENT,
    payload: payload,
  };
};
export const deleteDocumentSuccess = (results) => {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: results,
  };
};
export const deleteDocumentFail = (error) => {
  return {
    type: DELETE_DOCUMENT_FAIL,
    payload: error,
  };
};
