import {
	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
	GET_CHART_DATA_YEAR_SERVICE_FAIL,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_GROUP_SUCCESS,
	GET_CHART_DATA_AGE_GROUP_FAIL,
	GET_CHART_DATA_AGE_Salary_Group,
	GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
	GET_CHART_DATA_AGE_Salary_Group_FAIL,
} from "./actionTypes";

const initialState = {
	chartDataYearOfService: [],
	chartDataAgeGroup: [],
	chartDataSalaryGroup: [],
	loadingChartsYearOfservice: false,
	loadingChartsAgeGroup: false,
	loadingChartsSalaryGroup: false,
	yearofservice_error: {
		message: "",
	},
	agegroup_error: {
		message: "",
	},
	salarygroup_error: {
		message: "",
	},
};


const GetChartData = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_CHART_DATA_YEAR_SERVICE:
			state = { ...state, loadingChartsYearOfservice: true };
			break;
		case GET_CHART_DATA_YEAR_SERVICE_SUCCESS:
			state = { ...state, chartDataYearOfService: action.payload, loadingChartsYearOfservice: false };
			break;
		case GET_CHART_DATA_YEAR_SERVICE_FAIL:
			state = {
				...state,
				yearofservice_error: {
					message: "Error",
				},
				loadingChartsYearOfservice: false,
			};
			break;
		case GET_CHART_DATA_AGE_GROUP:
			state = { ...state, loadingChartsAgeGroup: true };
			break;
		case GET_CHART_DATA_AGE_GROUP_SUCCESS:
			state = { ...state, chartDataAgeGroup: action.payload, loadingChartsAgeGroup: false };
			break;
		case GET_CHART_DATA_AGE_GROUP_FAIL:
			state = {
				...state,
				agegroup_error: {
					message: "Error",
				},
				loadingCharts: false,
			};
			break;

		case GET_CHART_DATA_AGE_Salary_Group:
			state = { ...state, loadingChartsSalaryGroup: true };
			break;
		case GET_CHART_DATA_AGE_Salary_Group_SUCCESS:
			state = { ...state, chartDataSalaryGroup: action.payload, loadingChartsSalaryGroup: false };
			break;
		case GET_CHART_DATA_AGE_Salary_Group_FAIL:
			state = {
				...state,
				salarygroup_error: {
					message: "Error",
				},
				loadingChartsSalaryGroup: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
export default GetChartData;