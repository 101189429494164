
import { takeLatest, put, call } from "redux-saga/effects";

import { GET_EMPLOYEE, GET_EMPLOYEE_DEDUCTION, GET_EMPLOYEE_TYPES, ADVANCE_SEARCH,
	LOAD_COLUMNS,
	SUBSCRIBE_DEDUCTION,UNSUBSCRIBE_DEDUCTION, FINDASYOUTYPE} from "./actionTypes";

import {
  advanceSearchSuccess,
  advanceSearchFail,
  loadColumnsSuccess,
  loadColumnsFail,
  getEmployeeDeductionSuccess,
  getEmployeeDeductionFail,
  getEmployeeTypesSuccess,
  getEmployeeTypesFails,
  getEmployeeSuccess,
  getEmployeeFails,
  perfomDeductionSubscriptionSuccess,
  perfomDeductionSubscriptionFails,
  perfomDeductionUnSubscriptionSuccess,
  perfomDeductionUnSubscriptionFails,
  findAsyouTypeSuccess,
  findAsYouTypeFail,
} from "./actions";

import { advanceSearch,loadColumns,getDeductionPlan, getEmployeeTypes, getEmployee,
performNewSubscription,performActivateSubscription,performInActivateSubscription,findAsyouType } from "../../_helper/services/advanceSearch";

function* onGetEmployee({ payload: id }) {
	try {
	  const response = yield call(getEmployee, id);
	  yield put(getEmployeeSuccess(response));
	} catch (error) {
	  yield put(getEmployeeFails(error.response));
	}
  }

function* onGetEmployeeTypes() {
	try {
	  const response = yield call(getEmployeeTypes);
	  yield put(getEmployeeTypesSuccess(response));
	} catch (error) {
	  yield put(getEmployeeTypesFails(error.response));
	}
  }
function* onAdvanceSearch({ payload }) {
	try {
	  const response = yield call(advanceSearch,payload);
	  yield put(advanceSearchSuccess(response));
	} catch (error) {
	  yield put(advanceSearchFail(error.response));
	}
  }
  function* onLoadColumns({ payload: {tableName} }) {
	try {
	  const response = yield call(loadColumns,tableName);
	  yield put(loadColumnsSuccess(response));
	} catch (error) {
	  yield put(loadColumnsFail(error.response));
	}
  }

  function* onGetDeductionPlan() {
	try {
	  const response = yield call(getDeductionPlan);
	  yield put(getEmployeeDeductionSuccess(response));
	} catch (error) {
	  yield put(getEmployeeDeductionFail(error.response));
	}
  }
  function* onSubscribeDeduction({payload}) {
	try {
	  const response = yield call(performNewSubscription, payload);
	  yield put(perfomDeductionSubscriptionSuccess(response));
	} catch (error) {
	  yield put(perfomDeductionSubscriptionFails(error.response));
	}
  }
  function* onSubscribeChangeDeduction({ payload: {subscribe, DeductionCode} }) {
	try {
	  const response = yield call(subscribe === 'active'?performActivateSubscription:performInActivateSubscription, DeductionCode);
	  yield put(perfomDeductionUnSubscriptionSuccess(response));
	} catch (error) {
	  yield put(perfomDeductionUnSubscriptionFails(error.response));
	}
  }
  function* onFindAsYouType({ payload: {table,keyField,valueField,search} }) {
	try {
	  const response = yield call(findAsyouType,table,keyField,valueField,search);
	  yield put(findAsyouTypeSuccess(response));
	} catch (error) {
	  yield put(findAsYouTypeFail(error.response));
	}
  }
function* CartSaga() {
  yield takeLatest(ADVANCE_SEARCH, onAdvanceSearch);
  yield takeLatest(LOAD_COLUMNS, onLoadColumns);
  yield takeLatest(GET_EMPLOYEE_DEDUCTION, onGetDeductionPlan);
  yield takeLatest(GET_EMPLOYEE_TYPES, onGetEmployeeTypes);
  yield takeLatest(GET_EMPLOYEE, onGetEmployee);
  yield takeLatest(SUBSCRIBE_DEDUCTION, onSubscribeDeduction);
  yield takeLatest(UNSUBSCRIBE_DEDUCTION, onSubscribeChangeDeduction);
  yield takeLatest(FINDASYOUTYPE, onFindAsYouType);
}

export default CartSaga;