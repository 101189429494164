export const GET_CHART_DATA_YEAR_SERVICE = "GET_CHART_DATA_YEAR_SERVICE";
export const GET_CHART_DATA_YEAR_SERVICE_SUCCESS = "GET_CHART_DATA_YEAR_SERVICE_SUCCESS";
export const GET_CHART_DATA_YEAR_SERVICE_FAIL = "GET_CHART_DATA_YEAR_SERVICE_FAIL";

export const GET_CHART_DATA_AGE_GROUP = "GET_CHART_DATA_AGE_GROUP";
export const GET_CHART_DATA_AGE_GROUP_SUCCESS = "GET_CHART_DATA_AGE_GROUP_SUCCESS";
export const GET_CHART_DATA_AGE_GROUP_FAIL = "GET_CHART_DATA_AGE_GROUP_FAIL";

export const GET_CHART_DATA_AGE_Salary_Group = "GET_CHART_DATA_AGE_Salary_Group";
export const GET_CHART_DATA_AGE_Salary_Group_SUCCESS = "GET_CHART_DATA_AGE_Salary_Group_SUCCESS";
export const GET_CHART_DATA_AGE_Salary_Group_FAIL = "GET_CHART_DATA_AGE_Salary_Group_FAIL";