export const GET_POSTS = "/posts";
export const GET_POST_DETAILS = "/posts";
export const EMPLOYEE_TYPES = "/employeetypes";
export const EMPLOYEE_SEARCH = "/advancesearch/v2";
export const ADVANCE_SEARCH = "/advancesearch/v2";
export const ADVANCE_COMMON_SEARCH = "/advancesearch";
export const EMPLOYEE_DEDUCTION = "/employeeDeduction";
export const EMPLOYEE = "/employee";
export const GET_TABLE_NAMES_LIST = "/ColumnDescription/GetTablesName";
export const EMPLOYEE_LIST = "/employee/getcustomemployee";
export const GET_EMPLOYEE = "/Employee";
export const FIND_EMPLOYEE = "/Employee/FindEmployee";
export const EMPLOYEEAUDIT_HISTORY = "/Employee/audit-trail";
export const EMPLOYEE_TRANSACTIONS_LOGS = "/employee/transaction-logs";
export const EMPLOYEE_TRANSACTIONS_DETAILS = "/GG1/transaction/";
export const EMPLOYEE_DEDUCTION_SUBSCRIPTION = "/employeeDeduction";
export const ADVANCE_SEARCH_LOAD_COLUMNS =
  "/AdvanceSearch/GetColumnList?tableName";
export const ADVANCE_SEARCH_LOAD_FINDASTYPE = "/advancesearch/v2/find";
export const WORKFLOW_LIST = "/workflows";
export const WORKFLOW_CREATE = "/Workflows";
export const WORKFLOW_DETAILS = "/Workflows";
export const WORKFLOW_UPDATE = "/Workflows";
export const WORKFLOW_COPY = "/Workflows/copy";
export const WORKFLOW_DELETE = "/Workflows";
export const WORKFLOW_SETTING = "/workflowsetting";
export const WORKFLOW_SETTING_SAVE = "/workflowsetting/update";
export const EMPLOYEE_CERTIFICATION = "/EmployeeCertifications";
export const EMPLOYEE_TAG = "/EmployeeTag";
export const PROCESS_LIST = "/process";
export const PROCESS_CREATE = "/Processes";
export const PROCESS_DETAILS = "/processes";
export const PROCESS_UPDATE = "/Processes";
export const PROCESS_COPY = "/Processes/copy";
export const PROCESS_DELETE = "/Processes";
export const PROCESS_STATUS_LIST = "/LkProcessStatus/ByProcessID?ProcessID";
export const PROCESS_STATUS_CREATE = "/LkProcessStatus";
export const PROCESS_STATUS_UPDATE = "/LkProcessStatus";
export const PROCESS_STATUSLIST_UPDATE = "/LkProcessStatus/ProcessStatusList";
export const PROCESS_STATUS_DELETE = "/LkProcessStatus";
export const USER_CONFIGURATION = "/UserConfigurations";
export const FIND_OUTSIDE_EMPLOYEEMENT = "/OutsideEmployements/FindEmployee";
export const REQ_OUTSIDE_EMPLOYEEMENT = "/OutsideEmployements";
export const PAYROLLSTAGING_DIFFERENCE = "/PayrollMasterStagings/difference";
export const MOVE_TO_PAYROLL = "/PayrollMasterStagings/synctomaster";
export const REQ_LEAVE_APPLICATION = "/LeaveApplications";
export const FIND_LEAVE_APPLICATION = "/LeaveApplications/FindEmployee";
export const DOCUMENT_ATTACHMENT_CERTIFICATE =
  "/DocumentAttachments/FindByReferencceID";
export const POSITION_BASE = "/position";
export const DEPARTMENT_BASE = "/departmentdivision";
export const EDUCATION_BASE = "/educations";
export const ETHICGROUP_BASE = "/EthicGroups";
// export const EMPLOYEETYPES_BASE="/employee-type"
export const EMPLOYEETYPES_BASE = "/employeetypes";
export const PAYGRADE_BASE = "/lkpaygrades";
export const PAYSTEP_BASE = "/LkPaySteps";
export const PMRCNATURE_BASE = "/pmrcnatures";
export const DEDUCTIONPLAN_BASE = "/deductionplans";
export const VENDOR_BASE = "/vendors";
export const DEDUCTIONTYPE_BASE = "/deductiontypes";
export const PAYTIMECODE_BASE = "/paytimecodes";
export const PMRCSALARIES_BASE = "/pmrcsalaries";
export const CERTIFICATION_BASE = "/LkCertifications";
export const TAGMASTER_BASE = "/lktags";
export const HISTORY_BASE = "/PrHistoryClones";
export const DETAILS_BY_ADMIN = "/ColumnDescription/GetDetailsbyAdmin";
export const UPDATE_COLUMN = "/ColumnDescription/UpdateColumn";
// export const POSITIONS_DETAILS="/position/find";
// export const POSITION_BASE_UPDATE="/position/update/lookup";
// export const POSITION_DELETE="/position/delete";
export const OUTSIDEEMPLOYMENT_HISTORY = "/OutsideEmployements/audit-trail";
export const CALCULATE_HOURS = "/LeaveApplications/calculateHours";
export const LEAVEAPPLICATION_HISTORY = "/LeaveApplications/audit-trail";
export const LkDocumentSource = "/LkDocumentSource";
export const postDocuments = "/DocumentAttachment/UploadDocumentAttachment";
export const LEAVESHARE = "/LeaveShare/GetDetailsofleaves";

// Appointment apis
export const JOB_ANNOUNCEMENT = "/JobAnnouncement";
export const OJA = "/OJA";
export const Citizens = "/Citizens";

//Billing Invocie log API's
export const BILLING_INVOICE_LOG = "/DTBillingInvoiceLog";

// Carmens Own DT Log API's
export const ARCHIVAL_DT_LOG = "/DTCarmansOwnLog";

// DTPM Data Log API's
export const DTPM_DATA_LOG = "/DTDTPMDataLog";

// Test Designated Position Log API's
export const TEST_DESIGNATED_POSITION_LOG = "/DTDesignatedPositionLog";

// Department Random Date Log API's
export const DEPARTMENT_RANDOM_DATE_LOG = "/DTDeptRandomDateLog";

// Department JV Control Number API's
export const DEPARTMENT_JV_CONTROL_NUMBER = "/DTDepartmentJvControlNumber";

// Random DT Log API's
export const RANDOM_DT_LOG = "/DTRandomLog";

// TDP Employee Master List Log API's
export const TDP_EMPLOYEE_MASTER_LIST_LOG = "/DTTDPEmployeeMasterLog";

// TDP Employee Master List Log API's
export const DOA_HR_JV_INTERNAL_LOG = "/DTDOAHRJVInternalLog";

// MIS Data Log API's
export const MIS_DATA_LOG = "/DTMISDataLog";

// DFWP Contact List API's
export const DFWP_CONTACT_LIST = "/DTDFWPContactList";

// Vendor Log API's
export const VENDOR_LOG = "/DTVendorLog";

//DT API's
export const BILLING_INVOICE_STATUS_LISTS = "/LkBillingInvoiceStatus";
export const DT_TEST_TYPES_LISTS = "/LkDTTestType";
export const DT_TEST_RESULT_LISTS = "/LkDTResult";
export const DT_TEST_RESULT_DETAILS_LISTS = "/LkDrugType";
export const DT_TYPE_OF_COLLECTION = "/LkTypeOfCollection";
export const DT_NATURE_OF_ACTION = "/LkNaturalOfActions";
export const DT_RELEASED_TO = "/LkReleasedTo";
export const DT_FUNDING_STATUS = "/LkFundingStatus";
export const DT_RANDOM_REASON = "/LkRandomDTReason";
export const DT_TDP_TYPES = "/LkTDPType";
export const QUARTER_REPORT_DATE = "/LkQuarterReportDate";
export const VENDOR_LOG_STATUS_LIST = "/LkVendorStatus";

//EMR
export const ADVERSE_ACTION = "/EMRAdverseAction";
export const CBAR_LOG = "/EMRBarginingAgreement";
export const CBA_STATUS = "/LkCBAStatus";
export const MEDIA_SOURCE = "/LkMediaSource";
export const WAITING_PERIOD = "/LkWaitingPeriod";
export const TRAINING_LOG = "/EMRTrainingLog";
export const TYPE_OF_TRAINING = "/LkTypeOfTraining";
export const TYPE_OF_CHARGES = "/LkTypesOfCharge";
export const GRIEVANCE_APPEAL_STATUS = "/LkEmployeeGreivanceStatus";
export const NATURE_OF_GRIEVANCE = "/LkNatureOfGreivance";
export const GRIEVANCE_APPEAL = "/EMREmployeeGreivance";
export const LTD = "/EMRLongTermDesiblity";
export const TYPES_OF_WORK_HOURS = "/LkTypeOfWorkHour";
export const RWHR = "/EMRReduceWorkHour";
export const SPEC_MEDICAL_EVAL = "/EMRSpecialMedicalEvaualation";
export const STATUS_OF_APPT = "/LkStatusOfAppt";
export const TYPE_OF_SME = "/LkTypeOfSme";
export const EVAL_APPEAL = "/EMRPerformanceEvaluationAppeal";
export const PE_RATING = "/LkPeRating";
export const EVAL_APPEAL_REASON = "/LkEmployeeAppealReason";

//Upload document
export const DELETE_DOCUMENT = "/DocumentAttachment";

//DataVisuaization
export const GET_CHART_DATA_YEAR_SERVICE = "/DataVisualizationChart/GetChartDataForYearOfService"
export const GET_CHART_DATA_AGE_GROUP = "/DataVisualizationChart/GetChartDataForAgeGroup"
export const GET_CHART_DATA_SALARY_GROUP = "/DataVisualizationChart/GetChartDataForSalaryGroup"
// leaveshare
export const LEAVE_SHARE_FORM = "/LeaveShare/GetDetailsofleaves";
export const LEAVE_DELETE = "/LeaveShare/DonarDelete";
export const Leave_Create  = "/LeaveShare/LeaveShare";
export const Leave_Update  = "/LeaveShare/UpdateLeave";
export const Leave_GetInfo  = "/LeaveShare/GetEmployeeInfo";
export const MAIN_MENU = "/UserConfigurations";
export const GETAPI_MENU = '/UserConfigurations'
