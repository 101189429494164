import {
	ADVANCE_SEARCH,
	ADVANCE_SEARCH_SUCCESS,
	ADVANCE_SEARCH_FAIL,
	LOAD_COLUMNS,
	LOAD_COLUMNS_SUCCESS,
	LOAD_COLUMNS_FAIL,
	GET_EMPLOYEE_DEDUCTION,
	GET_EMPLOYEE_DEDUCTION_SUCCESS,
	GET_EMPLOYEE_DEDUCTION_FAIL,
	GET_EMPLOYEE_TYPES,
	GET_EMPLOYEE_TYPES_FAIL,
	GET_EMPLOYEE_TYPES_SUCCESS,
	GET_EMPLOYEE,
	GET_EMPLOYEE_SUCCESS,
	GET_EMPLOYEE_FAIL,
	SUBSCRIBE_DEDUCTION,
	SUBSCRIBE_DEDUCTION_SUCCESS,
	SUBSCRIBE_DEDUCTION_FAIL,
	UNSUBSCRIBE_DEDUCTION,
	UNSUBSCRIBE_DEDUCTION_SUCCESS,
	UNSUBSCRIBE_DEDUCTION_FAIL,
	FINDASYOUTYPE,
	FINDASYOUTYPE_SUCCESS,
	FINDASYOUTYPE_FAIL
  } from "./actionTypes";

  export const getEmployeeTypes =()=>{
	return {
		type: GET_EMPLOYEE_TYPES,
	}
  }
  export const getEmployeeTypesSuccess =(results)=>{
	return {
		type: GET_EMPLOYEE_TYPES_SUCCESS,
		payload: results
	}
  }
  export const getEmployeeTypesFails =(error)=>{
	return {
		type: GET_EMPLOYEE_TYPES_FAIL,
		payload: error
	}
  }
  export const advanceSearch = (parameters) => {
	return {
	  type: ADVANCE_SEARCH,
	  payload: parameters
	};
  };

  
  export const advanceSearchSuccess = (results) => {
	return {
	  type: ADVANCE_SEARCH_SUCCESS,
	  payload: results,
	};
  };
  
  export const advanceSearchFail = (error) => {
	return {
	  type: ADVANCE_SEARCH_FAIL,
	  payload: error,
	};
  };

  export const loadColumns = (tableName) => {
	return {
	  type: LOAD_COLUMNS,
	  payload: {tableName}
	};
  };

  
  export const loadColumnsSuccess = (results) => {
	return {
	  type: LOAD_COLUMNS_SUCCESS,
	  payload: results,
	};
  };
  
  export const loadColumnsFail = (error) => {
	return {
	  type: LOAD_COLUMNS_FAIL,
	  payload: error,
	};
  };
  
  export const getEmployeeDeduction = (id) => {
	return {
	  type: GET_EMPLOYEE_DEDUCTION,
	  payload: id,
	};
  };
  
  export const getEmployeeDeductionSuccess = (result) => {
	return {
	  type: GET_EMPLOYEE_DEDUCTION_SUCCESS,
	  payload: result,
	};
  };
  
  export const getEmployeeDeductionFail = (error) => {
	return {
	  type: GET_EMPLOYEE_DEDUCTION_FAIL,
	  payload: error,
	};
  };



  export const getEmployee =(employeeID)=>{
	return {
		type: GET_EMPLOYEE,
		payload: employeeID
	}
  }
  export const getEmployeeSuccess =(result)=>{
	return {
		type: GET_EMPLOYEE_SUCCESS,
		payload: result
	}
  }
  export const getEmployeeFails =(error)=>{
	return {
		type: GET_EMPLOYEE_FAIL,
		payload: error
	}
  }



  export const perfomDeductionSubscription =(payload)=>{
	return {
		type: SUBSCRIBE_DEDUCTION,
		payload
	}
  }
  export const perfomDeductionSubscriptionSuccess =(results)=>{
	return {
		type: SUBSCRIBE_DEDUCTION_SUCCESS,
		payload: results
	}
  }
  export const perfomDeductionSubscriptionFails =(error)=>{
	return {
		type: SUBSCRIBE_DEDUCTION_FAIL,
		payload: error
	}
  }


  export const perfomDeductionUnSubscription =(payload)=>{
	return {
		type: UNSUBSCRIBE_DEDUCTION,
		payload,
	}
  }
  export const perfomDeductionUnSubscriptionSuccess =(results)=>{
	return {
		type: UNSUBSCRIBE_DEDUCTION_SUCCESS,
		payload: results
	}
  }
  export const perfomDeductionUnSubscriptionFails =(error)=>{
	return {
		type: UNSUBSCRIBE_DEDUCTION_FAIL,
		payload: error
	}
  }

  export const findAsyouType =(payload)=>{
	return {
		type: FINDASYOUTYPE,
		payload,
	}
  }
  export const findAsyouTypeSuccess =(results)=>{
	return {
		type: FINDASYOUTYPE_SUCCESS,
		payload: results
	}
  }
  export const findAsYouTypeFail =(error)=>{
	return {
		type: FINDASYOUTYPE_FAIL,
		payload: error
	}
  }