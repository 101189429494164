import * as ActionTypes from "./actionTypes";

const initialState = {
  loading: false,
  payrollStagingDiffReducer: [],
  postingResult: {},
};
const PayrollStaging = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case ActionTypes?.PAYROLLSTAGING_DIFFERENCE:
      return { ...state, loading: true, payrollStagingDiffReducer: [] };
    case ActionTypes?.PAYROLLSTAGING_DIFFERENCE_SUCCESS: {
      console.log("action of reducer", action);
      return {
        ...state,
        payrollStagingDiffReducer: [...action.response],
        loading: false,
      };
    }
    case ActionTypes?.MOVE_TO_PAYROLL: {
      return { ...state, loading: true, postingResult: {} };
    }
    case ActionTypes?.MOVE_TO_PAYROLL_SUCCESS: {
      return {
        ...state,
        loading: false,
        postingResult: { ...action.response },
      };
    }
    default: {
      return state;
    }
  }
};
export default PayrollStaging;
