import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "ChartSlice";

export const fetchEthanicity = createAsyncThunk(
  `${sliceName}/fetchEthanicityData`,
  async (actnNo) => {
    try {
      //get DPDV

      //get nature of transactions

      let EthanicityItemsArray = [];
      try {
        const fetchEthanicityItems = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "LK_Ethnicity",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: " ID desc",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (fetchEthanicityItems) {

          fetchEthanicityItems.data.results.map((item) => {
            EthanicityItemsArray.push({
              value: item.Id,
              label: item.Description,
            });
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      console.log("lookup eth final", EthanicityItemsArray);
      return {
        EthanicityItems: EthanicityItemsArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
const ChartSlice = createSlice({
  name: sliceName,
  initialState: {
    EthanicityItems: [],
  },
  extraReducers: {
    [fetchEthanicity.fulfilled]: (state, action) => {
      console.log("action Eth ::::: ==> ", state, "#", action);
      state.EthanicityItems = action.payload.EthanicityItems;
      // ;
    },
  },
});

export default ChartSlice.reducer;
