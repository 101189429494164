import React, { lazy } from "react";
import { SilentRenewComponent } from "../SilentRenewComponent";
import CreateEmployeeGG1 from "../Components/CGITrackingNew/CreateEmployeeGG1";

const Position = lazy(() => import("../Components/LookupMaster/Position"));
const PositionAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Position/components/AddOrUpdate")
);
const MenuManagement = lazy(() => import("../Components/MenuManagement/Index"));
const Education = lazy(() => import("../Components/LookupMaster/Education"));
const EducationAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Education/components/EducationAddOrUpdate")
);
const EthicGroup = lazy(() => import("../Components/LookupMaster/EthicGroup"));
const EthicGroupAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/EthicGroup/components/EthicGroupAddOrUpdate"
  )
);
const Department = lazy(() => import("../Components/LookupMaster/Department"));
const DepartmentAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/Department/components/DepartmentAddOrUpdate"
  )
);
const SystemAdmin = lazy(() => import("../Components/SystemAdmin"));
const EmployeeType = lazy(() =>
  import("../Components/LookupMaster/EmployeeType")
);
const EmployeeTypeAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/EmployeeType/components/EmployeeTypeAddOrUpdate"
  )
);
const Paygrade = lazy(() => import("../Components/LookupMaster/Paygrade"));
const PaygradeAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Paygrade/components/PaygradeAddOrUpdate")
);
const Paystep = lazy(() => import("../Components/LookupMaster/Paystep"));
const PaystepAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Paystep/components/PaystepAddOrUpdate")
);
const PmrcNature = lazy(() => import("../Components/LookupMaster/PmrcNature"));
const PmrcAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PmrcNature/components/PmrcNatureAddOrUpdate"
  )
);
const IndividualDevelopmentPlan = lazy(() =>
  import("../Components/performanceEvaluation/IndividualDevelopmentPlan")
);
const WorkPlanning = lazy(() =>
  import("../Components/performanceEvaluation/WorkPlanning")
);
const DeductionPlans = lazy(() =>
  import("../Components/LookupMaster/DeductionPlans")
);
const DeductionPlansAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/DeductionPlans/components/DeductionPlansAddOrUpdate"
  )
);
const Vendor = lazy(() => import("../Components/LookupMaster/Vendor"));
const VendorAddorUpdate = lazy(() =>
  import("../Components/LookupMaster/Vendor/components/VendorAddOrUpdate")
);
const DeductionTypes = lazy(() =>
  import("../Components/LookupMaster/DeductionTypes")
);
const DeductiontypsAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/DeductionTypes/components/DeductionTypesAddOrUpdate"
  )
);

const Paytimecodes = lazy(() =>
  import("../Components/LookupMaster/PaytimeCodes")
);
const PaytimecodesAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PaytimeCodes/components/PaytimecodesAddOrUpdate"
  )
);
const PmrcSalaries = lazy(() =>
  import("../Components/LookupMaster/PmrcSalaries")
);
const PmrcSalariesAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PmrcSalaries/components/PmrcSalariesAddOrUpdate"
  )
);
const Certifications = lazy(() =>
  import("../Components/LookupMaster/Certifications")
);
const CertificationsAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/Certifications/components/CertificationsAddOrUpdate"
  )
);
const TagMaster = lazy(() => import("../Components/LookupMaster/TagMaster"));
const TagMasterAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/TagMaster/components/TagMasterAddOrUpdate")
);
const PrHistory = lazy(() => import("../Components/PrHistory"));
const PrHistoryAddOrUpdate = lazy(() =>
  import("../Components/PrHistory/components/PrHistoryAddOrUpdate")
);
const UnAuthorized = lazy(() => import("../Components/UnAuthorized"));
const WithoutLogin = lazy(() => import("../Components/WithoutLogin"));
const Logout = lazy(() => import("../Components/Logout/index"));
const MainMenu = lazy(() => import("../Components/MainMenu/index"));
// dashbaord

const Ecommerce = lazy(() => import("../Components/Dashboard/Ecommerce"));
const Employee = lazy(() => import("../Components/Dashboard/Employee"));
const TransactionLogs = lazy(() => import("../Components/TransactionLog"));
const DashBoardLog = lazy(() => import("../Components/viewDashboard"));
const TransactionLogDetails = lazy(() =>
  import("../Components/TransactionLog/details")
);
const Announcement = lazy(() => import("../Components/announcement"));
const ChatAppContain = lazy(() => import("../Components/Chat/ChatApp"));
const LeaveShare = lazy(() =>
  import("../Components/Leave/leaveShare/leaveShare")
);

const PayrollStagingList = lazy(() =>
  import("../Components/PayrollStaging/components/PayrollStagingList")
);
const LeaveApplicationWithFormik = lazy(() =>
  import("../Components/Leave/LeaveApplication")
);
const OutsideEmploymentWithFormik = lazy(() =>
  import("../Components/OutsideEmployment")
);
const PerformanceEvaluation = lazy(() =>
  import("../Components/performanceEvaluation/performanceEvaluation")
);
const OnlineRecruitment = lazy(() =>
  import("../Components/online-recruitment/online-recruitment")
);
const RecruitmentFormData = lazy(() =>
  import("../Components/online-recruitment/component/recruitment-form")
);
const JobApplicationFormData = lazy(() =>
  import("../Components/online-recruitment/component/jobApplicationForm")
);
const MyApplications = lazy(() =>
  import("../Components/online-recruitment/component/myapplications")
);
const PayrollMasterDetails = lazy(() =>
  import("../Components/payroll/payrollMasterDetails")
);
const MasterDetails = lazy(() => import("../Components/payroll/index"));
const EmployeeManagement = lazy(() =>
  import("../Components/EmployeeManagement")
);
const DocumentManagement = lazy(() =>
  import("../Components/DocumentManagement")
);
const BenefitMasterDetail = lazy(() =>
  import("../Components/ins-enrollment/benefitMasterDetail")
);
const LifeEnrollDetail = lazy(() =>
  import("../Components/ins-enrollment/lifeEnrollDetail")
);
const HealthEnrollDetail = lazy(() =>
  import("../Components/ins-enrollment/healthEnrollDetail")
);
const DeathClaims = lazy(() => import("../Components/deathClaims/DeathClaims"));
const EmpDetails = lazy(() => import("../Components/AdvanceSearch"));
const PayrollWorkTime = lazy(() =>
  import("../Components/payroll/payrollWorkTime")
);
const GG1Details = lazy(() => import("../Components/CGITrackingNew/details"));
const Workflow = lazy(() => import("../Components/Workflow"));
const ManageWorkflow = lazy(() =>
  import("../Components/Workflow/ManageWorkflow")
);
const WorkflowConfiguration = lazy(() =>
  import("../Components/Workflow/WorkflowConfiguration")
);
const Process = lazy(() => import("../Components/Process/Index"));
const ProcessStatus = lazy(() =>
  import("../Components/Process/components/ProcessStatus")
);
const CreateTransaction = lazy(() =>
  import("../Components/Create Transaction/TransactionList")
);
const CreateTransactions = lazy(() =>
  import("../Components/Create Transaction/CreateTransactions")
);
const DashboardChart = lazy(() =>
  import("../Components/DashboardChart/Default")
); // import Default from "";
const Default = lazy(() => import("../Components/Dashboard/Default")); // import Default from "";
const GG1List = lazy(() => import("../Components/CGITrackingNew/GG1List"));
const GG1Request = lazy(() =>
  import("../Components/CGITrackingNew/GG1Request")
);

//Drug Testing
const BillingInvoiceLogLists = lazy(() =>
  import("../Components/DrugTesting/BillingInvoiceLog")
);
const CreateBillingInvoice = lazy(() =>
  import("../Components/DrugTesting/BillingInvoiceLog/CreateBillingInvoice")
);

const CarmensOwnDTLog = lazy(() =>
  import("../Components/DrugTesting/CarmensOwnDTLog")
);
const CreateCarmesOwnDTLog = lazy(() =>
  import("../Components/DrugTesting/CarmensOwnDTLog/CreateCarmesOwnDTLog")
);

const DTPMDataLog = lazy(() => import("../Components/DrugTesting/DTPMDataLog"));
const CreateDTPMDataLog = lazy(() =>
  import("../Components/DrugTesting/DTPMDataLog/CreateDTPMDataLog")
);

const TestDesignatedPositionLog = lazy(() =>
  import("../Components/DrugTesting/TestDesignatedPositionLog")
);
const CreateTestDesignatedPositionLog = lazy(() =>
  import(
    "../Components/DrugTesting/TestDesignatedPositionLog/CreateTestDesignatedPositionLog"
  )
);

const DepartmentRandomDateLog = lazy(() =>
  import("../Components/DrugTesting/DepartmentRandomDateLog")
);
const CreateDepartmentRandomDateLog = lazy(() =>
  import(
    "../Components/DrugTesting/DepartmentRandomDateLog/CreateDepartmentRandomDateLog"
  )
);

const TDPEmployeeMasterListLog = lazy(() =>
  import("../Components/DrugTesting/TDPEmployeeMasterListLog")
);
const CreateTDPEmployeeMasterListLog = lazy(() =>
  import(
    "../Components/DrugTesting/TDPEmployeeMasterListLog/CreateTDPEmployeeMasterListLog"
  )
);

const RandomDTLog = lazy(() => import("../Components/DrugTesting/RandomDTLog"));
const CreateRandomDTLog = lazy(() =>
  import("../Components/DrugTesting/RandomDTLog/CreateRandomDTLog")
);

const DepartmentsJVControlNumber = lazy(() =>
  import("../Components/DrugTesting/DepartmentsJVControlNumber")
);
const CreateDepartmentsJVControlNumber = lazy(() =>
  import(
    "../Components/DrugTesting/DepartmentsJVControlNumber/CreateDepartmentsJVControlNumber"
  )
);

const DFWPContactList = lazy(() =>
  import("../Components/DrugTesting/DFWPContactList")
);
const CreateDFWPContactList = lazy(() =>
  import("../Components/DrugTesting/DFWPContactList/CreateDFWPContactList")
);

const VenderLog = lazy(() => import("../Components/DrugTesting/VenderLog"));
const CreateVenderLog = lazy(() =>
  import("../Components/DrugTesting/VenderLog/CreateVenderLog")
);

const DOA_HRJVInternalLog = lazy(() =>
  import("../Components/DrugTesting/DOA-HRJVInternalLog")
);
const CreateHRJVInternalLog = lazy(() =>
  import("../Components/DrugTesting/DOA-HRJVInternalLog/CreateHRJVInternalLog")
);

const MISDataLog = lazy(() => import("../Components/DrugTesting/MISDataLog"));
const CreateMISDataLog = lazy(() =>
  import("../Components/DrugTesting/MISDataLog/CreateMISDataLog")
);

const EmployeeLeaveShareForm = lazy(() =>
  import("../Components/EMR/EmployeeLeaveShare/EmployeeLeaveShareForm")
);
const CreateEmployeeLeaveShare = lazy(() =>
  import("../Components/EMR/EmployeeLeaveShare/CreateEmployeeLeaveShare")
);

const PerformanceEvalution = lazy(() =>
  import("../Components/EMR/PerformanceEvaluation")
);
const CreatePerformanceEvalution = lazy(() =>
  import("../Components/EMR/PerformanceEvaluation/CreatePerformanceEvaluation")
);

const PerformanceEvalutionAppeal = lazy(() =>
  import("../Components/EMR/PerformanceEvaluationAppeal")
);
const CreatePerformanceEvalutionAppeal = lazy(() =>
  import(
    "../Components/EMR/PerformanceEvaluationAppeal/CreatePerformanceEvaluationAppeal"
  )
);

const OutsideEmploymentRecord = lazy(() =>
  import("../Components/EMR/OutsideEmploymentRecord")
);
const CreateOutsideEmploymentRecord = lazy(() =>
  import(
    "../Components/EMR/OutsideEmploymentRecord/CreateOutsideEmploymentRecord"
  )
);

const EMRTrainingLog = lazy(() => import("../Components/EMR/EMRTrainingLog"));
const CreateEMRTrainingLog = lazy(() =>
  import("../Components/EMR/EMRTrainingLog/CreateEMRTrainingLog")
);

const AdverseAction = lazy(() => import("../Components/EMR/AdverseAction"));
const CreateAdverseAction = lazy(() =>
  import("../Components/EMR/AdverseAction/CreateAdverseAction")
);

const GrievanceRequest = lazy(() =>
  import("../Components/EMR/GrievanceRequest")
);
const CreateGrievanceRequest = lazy(() =>
  import("../Components/EMR/GrievanceRequest/CreateGrievanceRequest")
);

const SpecialMedicalEvaluation = lazy(() =>
  import("../Components/EMR/SpecialMedicalEvaluation")
);
const CreateSpecialMedicalEvaluation = lazy(() =>
  import(
    "../Components/EMR/SpecialMedicalEvaluation/CreateSpecialMedicalEvaluation"
  )
);

const CollectiveBargainingAgreementRequest = lazy(() =>
  import("../Components/EMR/CollectiveBargainingAgreementRequest")
);
const CreateCollectiveBargainingAgreementRequest = lazy(() =>
  import(
    "../Components/EMR/CollectiveBargainingAgreementRequest/CreateCollectiveBargainingAgreementRequest"
  )
);

const LongTermDisability = lazy(() =>
  import("../Components/EMR/LongTermDisability")
);
const CreateLongTermDisability = lazy(() =>
  import("../Components/EMR/LongTermDisability/CreateLongTermDisability")
);

const ReduceWorkHourRecord = lazy(() =>
  import("../Components/EMR/ReduceWorkHourRecord")
);
const CreateReduceWorkHourRecord = lazy(() =>
  import("../Components/EMR/ReduceWorkHourRecord/CreateReduceWorkHourRecord")
);
const NPAList = lazy(() => import("../Components/Records/NPA"));
const CreateNPATransactions = lazy(() =>
  import("../Components/Records/NPAForms/NewCreateTransactions")
);
const Inprogress = lazy(() => import("../Components/Inprogress/index"));

const Charts = lazy(() => import("../Components/Charts"));

const AuditMasterLog = lazy(() => import("../Components/AuditMasterLog/index"));

export const routes = [
  {
    path: `${process.env.REACT_APP_PREFIX_URL}silentrenew`,
    auth: ["user", "HRAdmin", "DepartmentAdmin"],
    Component: <SilentRenewComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/chart`,
    Component: <DashboardChart />,
    _roles: ["HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/employee`,
    Component: <Employee />,
  },
  // master details
  {
    path: `${process.env.PUBLIC_URL}/individual-development-plan`,
    Component: <IndividualDevelopmentPlan />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/individual-development-plan/:employeeid/:id`,
    Component: <IndividualDevelopmentPlan />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/work-planning/:employeeid/:id`,
    Component: <WorkPlanning />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail`,
    // Component: <PayrollMasterDetails />,
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail/new`,
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail/:employeeID`,
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/announcement`,
    Component: <Announcement />,
  },
  //   { path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatAppContain /> },
  {
    path: `${process.env.PUBLIC_URL}/share`,
    Component: <LeaveShare />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication`,
    Component: <LeaveApplicationWithFormik />,
  },
  // { path: `${process.env.PUBLIC_URL}/leave/:id`, Component: <LeaveShare /> },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id/:RecordId`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payroll-work/:id`,
    Component: <PayrollWorkTime />,
  },

  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation`,
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid`,
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid/:id`,
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "reviewer",
      "user",
      "appointingAuthority",
    ],
    // exact: true,
  },

  {
    path: "/online-recruitment",
    exact: true,
    Component: <OnlineRecruitment />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply-job/:id",
    Component: <JobApplicationFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply/:id/applied",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  // {
  //   path: "/online-recruitment/apply/:id",
  //   Component: <RecruitmentFormData />,
  //   exact: true,
  // },
  {
    path: "/online-recruitment/edit/:id",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/my-application",
    exact: true,
    Component: <MyApplications />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/employee-management",
    exact: true,
    Component: <EmpDetails />,
    // _roles: ["Developer"],
  },
  {
    path: "/document-management",
    exact: true,
    Component: <DocumentManagement />,
    // _roles: ["Developer"],
  },
  {
    path: `/transactionlist`,
    _roles: ["HRAdmin", "DepartmentAdmin"],
    Component: <CreateTransaction />,
  },
  {
    path: "/ins-enrollment/death-claims/:id",
    Component: <DeathClaims />,
  },
  {
    path: "/ins-enrollment/benefit-master-detail",
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll",
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/benefit-master-detail/:id",
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment",
    Component: <BenefitMasterDetail />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll/:id",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll/:id",
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/advance-search",
    exact: true,
    Component: <EmpDetails />,
  },
  {
    path: `${process.env.REACT_APP_PREFIX_URL}silentrenew`,
    auth: ["user", "HRAdmin", "DepartmentAdmin"],
    Component: <SilentRenewComponent />,
  },
  //dashboard
  { path: `${process.env.PUBLIC_URL}`, Component: <Default /> },
  {
    // path: `${process.env.PUBLIC_URL}/dashboard/default`,
    path: `/dashboard/default`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/employee`,
    Component: <Employee />,
  },
  // master details
  {
    path: `${process.env.PUBLIC_URL}/master-detail`,
    Component: <PayrollMasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking`,
    Component: <GG1List />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/new-request`,
    Component: <GG1Request />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/new-request/:ssn`,
    Component: <GG1Request />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/employeecreate-gg1/:ssn`,
    Component: <CreateEmployeeGG1 />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/employeecreate-gg1`,
    Component: <CreateEmployeeGG1 />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/payrollstaging`,
    Component: <PayrollStagingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/:employeeID/:actn_no`,
    Component: <GG1Details />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction`,
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction/:employeeID`,
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    // path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:actionNo`,
    path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:hrControl`,
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transactionwithnoa/:employeeID/:noa`,
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:noa`,
  //   Component: <CreateTransactions />,
  //   _roles: ["Developer"],
  // },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log`,
    Component: <TransactionLogs />,
    _roles: ["user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log/view-transaction/:employeeID/:actn_no`,
    Component: <TransactionLogDetails />,
    _roles: ["Developer", "user", "HRAdmin"],
  },

  {
    path: `${process.env.PUBLIC_URL}/transaction-log/:parentPage/:employeeID`,
    Component: <TransactionLogs />,
    _roles: ["Developer", "user", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log/:employeeID`,
    Component: <TransactionLogs />,
    _roles: ["Developer", "HRAdmin"],
  },

  {
    path: `${process.env.PUBLIC_URL}/view-dashboard/:employeeID`,
    Component: <DashBoardLog />,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/announcement`,
    Component: <Announcement />,
  },
  { path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatAppContain /> },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication/:id`,
    Component: <LeaveApplicationWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication/:id/:leaveid`,
    Component: <LeaveApplicationWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leave/leaveShare`,
    Component: <LeaveShare />,
  },
  // leave share by id
  { path: `${process.env.PUBLIC_URL}/leave/:leave/:LeaveShareID`, Component: <LeaveShare /> },
  
  {
    path: `${process.env.PUBLIC_URL}/outside-employment`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation`,
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "user",
      "Developer",
      "supervisor",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid`,
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: "/online-recruitment",
    exact: true,
    Component: <OnlineRecruitment />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply/:id",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/edit/:id",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/my-application",
    exact: true,
    Component: <MyApplications />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/ins-enrollment/death-claims/:id",
    Component: <DeathClaims />,
  },
  {
    path: "/ins-enrollment/benefit-master-detail",
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll",
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/benefit-master-detail/:id",
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment",
    Component: <BenefitMasterDetail />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll/:id",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll/:id",
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/workflow",
    Component: <Workflow />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path: "/system",
    path: `${process.env.PUBLIC_URL}/column-lookup`,
    Component: <SystemAdmin />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/menuManagement",
    Component: <MenuManagement />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/workflow/:id",
    Component: <ManageWorkflow />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/workflow/configuration",
    Component: <WorkflowConfiguration />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/process/:id",
    Component: <ProcessStatus />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/process",
    Component: <Process />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/position",
    Component: <Position />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/inprogress",
    Component: <Inprogress />,
    exact: true,
    // _roles: ["Developer"],
  },
  {
    path: "/position/:id",
    Component: <PositionAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/position/new",
    Component: <PositionAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/education",
    Component: <Education />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/educations/:id",
    Component: <EducationAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/educations/new",
    Component: <EducationAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup",
    Component: <EthicGroup />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup/:id",
    Component: <EthicGroupAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup/new",
    Component: <EthicGroupAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision",
    Component: <Department />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision/:id",
    Component: <DepartmentAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision/new",
    Component: <DepartmentAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employeetypes",
    path: "/employee-type",
    Component: <EmployeeType />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employee-type/:id",
    path: "/employeetypes/:id",
    Component: <EmployeeTypeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employee-type/new",
    path: "/employeetypes/new",
    Component: <EmployeeTypeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paygrade",
    Component: <Paygrade />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/lkpaygrades/:id",
    Component: <PaygradeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/lkpaygrades/new",
    Component: <PaygradeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paystep",
    Component: <Paystep />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkPaySteps/:paY_GRADE/:paY_STEP",
    Component: <PaystepAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkPaySteps/new",
    Component: <PaystepAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrc-nature",
    Component: <PmrcNature />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/pmrc-nature/:id",
    path: "/pmrcnatures/:id",
    Component: <PmrcAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/pmrc-nature/new",
    path: "/pmrcnatures/new",
    Component: <PmrcAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans",
    Component: <DeductionPlans />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans/:code",
    Component: <DeductionPlansAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans/new",
    Component: <DeductionPlansAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  {
    path: "/vendors",
    Component: <Vendor />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/vendors/:id",
    Component: <VendorAddorUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/vendors/new",
    Component: <VendorAddorUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductiontypes",
    Component: <DeductionTypes />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductiontypes/:code",
    Component: <DeductiontypsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductiontypes/new",
    Component: <DeductiontypsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "paytimecodes",
    Component: <Paytimecodes />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paytimecodes/:code",
    Component: <PaytimecodesAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paytimecodes/new",
    Component: <PaytimecodesAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrc-salaries",
    Component: <PmrcSalaries />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrcsalaries/:grade/:step",
    Component: <PmrcSalariesAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrcsalaries/new",
    Component: <PmrcSalariesAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/certifications",
    Component: <Certifications />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkCertifications/:code",
    Component: <CertificationsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkCertifications/new",
    Component: <CertificationsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  // {
  // 	path: "/advance-search",
  // 	exact: true,
  // 	Component: <EmpDetails />,
  // }

  {
    path: "/tagmaster",
    Component: <TagMaster />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/Lktags/:code",
    Component: <TagMasterAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/Lktags/new",
    Component: <TagMasterAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  {
    path: "/legacy-payroll",
    Component: <PrHistory />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/legacy-payroll/:id",
    Component: <PrHistoryAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/legacy-payroll/new",
    Component: <PrHistoryAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/unauthorized",
    Component: <UnAuthorized />,
    exact: true,
    layout: "none",
  },
  {
    path: "/withoutlogin",
    Component: <WithoutLogin />,
    exact: true,
    layout: "none",
  },
  {
    path: "/logout",
    Component: <Logout />,
    exact: true,
    layout: "none",
  },
  {
    path: "/billing-invoice-log",
    Component: <BillingInvoiceLogLists />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/billing-invoice-log/new",
    Component: <CreateBillingInvoice />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/billing-invoice-log/:id",
    Component: <CreateBillingInvoice />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log",
    Component: <CarmensOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log/new",
    Component: <CreateCarmesOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log/:id",
    Component: <CreateCarmesOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log",
    Component: <DTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log/new",
    Component: <CreateDTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log/:id",
    Component: <CreateDTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log",
    Component: <TestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log/new",
    Component: <CreateTestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log/:id",
    Component: <CreateTestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log",
    Component: <DepartmentRandomDateLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log/new",
    Component: <CreateDepartmentRandomDateLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log/:id",
    Component: <CreateDepartmentRandomDateLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log",
    Component: <TDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log/new",
    Component: <CreateTDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log/:id",
    Component: <CreateTDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log",
    Component: <RandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log/new",
    Component: <CreateRandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log/:id",
    Component: <CreateRandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number",
    Component: <DepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number/new",
    Component: <CreateDepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number/:id",
    Component: <CreateDepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list",
    Component: <DFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list/new",
    Component: <CreateDFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list/:id",
    Component: <CreateDFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/vendor-log",
    Component: <VenderLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/vendor-log/new",
    Component: <CreateVenderLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/vendor-log/:id",
    Component: <CreateVenderLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log",
    Component: <DOA_HRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log/new",
    Component: <CreateHRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log/:id",
    Component: <CreateHRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log",
    Component: <MISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log/new",
    Component: <CreateMISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log/:id",
    Component: <CreateMISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/sharess",
    Component: <EmployeeLeaveShareForm />,
    exact: true,
    _roles: ["Developer"],
  },
  // {
  //   path: "/employee-leave-share",
  //   Component: <CreateEmployeeLeaveShare />,
  //   exact: true,
  //   roles: ["HRAdmin"],
  // },
  {
    path: "/employee-leave-share/new",
    Component: <CreateEmployeeLeaveShare />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/employee-performance-evaluation",
    Component: <CreatePerformanceEvalution />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/employee-performance-evaluation/new",
    Component: <CreatePerformanceEvalution />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal",
    Component: <PerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal/new",
    Component: <CreatePerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal/:id",
    Component: <CreatePerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/outside-employment-record",
    Component: <CreateOutsideEmploymentRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/outside-employment-record/new",
    Component: <CreateOutsideEmploymentRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log",
    Component: <EMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log/new",
    Component: <CreateEMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log/:id",
    Component: <CreateEMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action",
    Component: <AdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action/new",
    Component: <CreateAdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action/:id",
    Component: <CreateAdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request",
    Component: <GrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request/new",
    Component: <CreateGrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request/:id",
    Component: <CreateGrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log",
    Component: <SpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log/new",
    Component: <CreateSpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log/:id",
    Component: <CreateSpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request",
    Component: <CollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request/new",
    Component: <CreateCollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request/:id",
    Component: <CreateCollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/long-term-disability",
    Component: <LongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/long-term-disability/new",
    Component: <CreateLongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/long-term-disability/:id",
    Component: <CreateLongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record",
    Component: <ReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record/new",
    Component: <CreateReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record/:id",
    Component: <CreateReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/npa-records`,
    Component: <NPAList />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/npa/transaction/:employeeID/:actionNo`,
    Component: <CreateNPATransactions />,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/charts`,
    Component: <Charts />,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/audit-master-log`,
    Component: <AuditMasterLog />,
    _roles: ["HRAdmin", "Developer"]
  },
];
