import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LI, UL, H6 } from "../../AbstractElements";
import { MENUITEMS } from "./Menu";
import CustomizerContext from "../../_helper/Customizer";
import { Label } from "reactstrap";
import { useAuth } from "oidc-react";
import { useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

const NewSidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  // eslint-disable-next-line
  const {
    storedTheme,
    storedTheme: { pin_menuItem },
    StoredUserConfiguration: { pin_menuItem: pin_menuItemSaved },
  } = useSelector((state) => state?.ThemeCustomizerReducer);

  const { t } = useTranslation();
  const { userData } = useAuth();
  const [menuItems, setMenuItems] = useState(MENUITEMS);
  const { addPinMenuItem } = useContext(CustomizerContext);
  const pinItem = async (itemId) => {
    // debugger;
    const itemToPin = menuItems[0]?.Items.find((item) => item.id === itemId);
    if (
      pin_menuItem === undefined ||
      pin_menuItem.length === undefined ||
      pin_menuItem.length === 0
    ) {
      await addPinMenuItem([itemToPin]);
    } else {
      const exitedItem =
        pin_menuItem !== undefined && pin_menuItem.length > 0
          ? pin_menuItem?.filter((d) => d.id === itemId)
          : [];
      if (itemToPin && exitedItem?.length === 0) {
        await addPinMenuItem([...pin_menuItem, itemToPin]);
      }
    }
  };

  const unpinItem = async (itemId) => {
    const itemToUnpin = pin_menuItem.find((item) => item.id === itemId);

    if (itemToUnpin) {
      // Remove the item from pinnedItems
      const updatedPinnedItems = pin_menuItem.filter(
        (item) => item.id !== itemId
      );

      await addPinMenuItem([...updatedPinnedItems]);
    }
  };

  useEffect(() => {
    if (userData && userData.profile?.role) {
      setMenuItems([
        ...MENUITEMS.filter((d) => {
          if (d.Items && d.Items.length > 0) {
            d.Items = d.Items.filter((c) => {
              if (c.children && c.children.length > 0) {
                c.children = c.children.filter((b) => {
                  return (
                    (b.roles &&
                      userData?.profile?.role &&
                      b.roles.indexOf(userData?.profile?.role) > -1) ||
                    b.roles === undefined
                  );
                });
              }
              return (
                (c.roles &&
                  userData?.profile?.role &&
                  c.roles.indexOf(userData?.profile?.role) > -1) ||
                c.roles === undefined
              );
            });
          }
          return (
            (d.roles &&
              userData?.profile?.role &&
              d.roles.indexOf(userData?.profile?.role) > -1) ||
            d.roles === undefined
          );
        }),
      ]);
    }
  }, [userData]);

  useEffect(() => {
    console.log("menu items", menuItems);
  }, [menuItems]);
  const toggletNavActive = (item) => {
    if (!item.active) {
      menuItems.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: menuItems });
  };
  return (
    <Fragment>
      <UL attrUL={{ className: "nav-menu custom-scrollbar" }}>
        <LI attrLI={{ className: "back-btn" }}>
          <div className="mobile-back text-end">
            <span>Back</span>
            <i className="fa fa-angle-right ps-2"></i>
          </div>
        </LI>
        {pin_menuItem?.length > 0 &&
          pin_menuItem.map((menuItem, i) => {
            return (
              <Fragment key={i}>
                {i === 0 && (
                  <LI attrLI={{ className: "sidebar-main-title" }}>
                    <div>
                      <H6>{t("Pinned")}</H6>
                    </div>
                  </LI>
                )}
                <LI attrLI={{ className: "dropdown" }}>
                  {menuItem?.type === "sub" && (
                    <a
                      href="javascript"
                      id="nav-link"
                      className={`nav-link menu-title ${
                        menuItem.active ? "active" : ""
                      }`}
                      onClick={(event) => {
                        event.preventDefault();
                        setNavActive(menuItem);
                      }}
                    >
                      {menuItem?.icon !== undefined && (
                        <i
                          className={`fa-duotone fa-${menuItem?.icon} font-primary`}
                        ></i>
                      )}
                      <span>{t(menuItem?.title)}</span>

                      <div className="according-menu">
                        <i
                          onClick={(e) => {
                            e.preventDefault();
                            unpinItem(menuItem?.id);
                          }}
                          style={{ marginRight: "6px" }}
                          className={`fa-duotone fa-link-slash font-primary pin-icon`}
                        ></i>
                        {menuItem.active ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </div>
                    </a>
                  )}
                  {menuItem?.type === "link" && (
                    <Link
                      to={menuItem?.path}
                      id="nav-link"
                      className={`nav-link menu-title ${
                        menuItem?.active ? "active" : ""
                      }`}
                      onClick={() => toggletNavActive(menuItem)}
                    >
                      {menuItem?.icon !== undefined && (
                        <i
                          className={`fa-duotone fa-${menuItem?.icon} font-primary`}
                        ></i>
                      )}

                      <span>{t(menuItem?.title)}</span>

                      {menuItem?.badge && (
                        <Label className={menuItem?.badge}>
                          {menuItem?.badgetxt}
                        </Label>
                      )}
                    </Link>
                  )}
                  {menuItem.children && (
                    <UL
                      attrUL={{
                        className: "simple-list sidebar-submenu",
                        style: menuItem.active
                          ? sidebartoogle
                            ? {
                                opacity: 1,
                                transition: "opacity 500ms ease-in",
                              }
                            : { display: "block" }
                          : { display: "none" },
                      }}
                    >
                      <UL attrUL={{ className: "nav-submenu menu-content" }}>
                        {menuItem.children.map((childrenItem, index) => {
                          return (
                            <LI key={index}>
                              {childrenItem.type === "link" && (
                                <Link
                                  to={childrenItem.path}
                                  className={`${
                                    childrenItem.active ? "active" : ""
                                  }`}
                                  onClick={() => toggletNavActive(childrenItem)}
                                >
                                  {t(childrenItem.title)}
                                </Link>
                              )}
                              {childrenItem.type === "sub" && (
                                <a
                                  href="#javascript"
                                  className={`${
                                    childrenItem.active ? "active" : ""
                                  }`}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggletNavActive(childrenItem);
                                  }}
                                >
                                  {t(childrenItem.title)}
                                  <div className="according-menu">
                                    {childrenItem.active ? (
                                      <i className="fa fa-caret-down"></i>
                                    ) : (
                                      <i className="fa fa-caret-right"></i>
                                    )}{" "}
                                  </div>
                                </a>
                              )}
                              {childrenItem.type === "exteral_link" && (
                                <>
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      toggletNavActive(childrenItem);
                                    }}
                                  >
                                    {t(childrenItem.title)}
                                  </Link>
                                </>
                              )}
                              {childrenItem.children && (
                                <UL
                                  attrUL={{
                                    className:
                                      "simple-list nav-sub-childmenu submenu-content",
                                    style: childrenItem.active
                                      ? { display: "block" }
                                      : { display: "none" },
                                  }}
                                >
                                  {childrenItem.children.map(
                                    (childrenSubItem, key) => (
                                      <LI key={key}>
                                        {childrenSubItem.type === "link" && (
                                          <Link
                                            to={childrenSubItem.path}
                                            className={`${
                                              childrenSubItem.active
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggletNavActive(childrenSubItem)
                                            }
                                          >
                                            {t(childrenSubItem.title)}
                                          </Link>
                                        )}
                                      </LI>
                                    )
                                  )}
                                </UL>
                              )}
                            </LI>
                          );
                        })}
                      </UL>
                    </UL>
                  )}
                </LI>
              </Fragment>
            );
          })}
        {menuItems.length > 0 &&
          menuItems.map((Item, i) => {
            return (
              <Fragment key={i}>
                <LI attrLI={{ className: "sidebar-main-title" }}>
                  <div>
                    <H6>{t(Item.menutitle)}</H6>
                  </div>
                </LI>
                {Item.Items.map((menuItem, i) => {
                  if (
                    pin_menuItem != null &&
                    pin_menuItem != undefined &&
                    pin_menuItem.length > 0
                  ) {
                    const pinItem = pin_menuItem.filter(
                      (d) => d.id === menuItem.id
                    );
                    if (pinItem !== undefined && pinItem.length > 0) {
                      return null;
                    }
                  }
                  return (
                    <LI attrLI={{ className: "dropdown" }} key={i}>
                      {menuItem.type === "sub" && (
                        <a
                          href="javascript"
                          id="nav-link"
                          className={`nav-link menu-title ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setNavActive(menuItem);
                          }}
                        >
                          {menuItem.icon !== undefined && (
                            <i
                              className={`fa-duotone fa-${menuItem.icon} font-primary`}
                            ></i>
                          )}
                          <span>{t(menuItem.title)}</span>

                          <div className="according-menu">
                            <i
                              onClick={(e) => {
                                e.preventDefault();
                                pinItem(menuItem?.id);
                              }}
                              style={{ marginRight: "6px" }}
                              className={`fa-duotone fa-thumbtack font-primary pin-icon`}
                            ></i>
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      )}
                      {menuItem.type === "link" && (
                        <Link
                          to={menuItem.path}
                          id="nav-link"
                          className={`nav-link menu-title ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          {menuItem.icon !== undefined && (
                            <i
                              className={`fa-duotone fa-${menuItem.icon} font-primary`}
                            ></i>
                          )}

                          <span>{t(menuItem.title)}</span>

                          {menuItem.badge && (
                            <Label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </Label>
                          )}
                        </Link>
                      )}
                      {menuItem.children && (
                        <UL
                          attrUL={{
                            className: "simple-list sidebar-submenu",
                            style: menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" },
                          }}
                        >
                          <UL
                            attrUL={{ className: "nav-submenu menu-content" }}
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <LI key={index}>
                                  {childrenItem.type === "link" && (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {t(childrenItem.title)}
                                    </Link>
                                  )}
                                  {childrenItem.type === "sub" && (
                                    <a
                                      href="#javascript"
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggletNavActive(childrenItem);
                                      }}
                                    >
                                      {t(childrenItem.title)}
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-caret-down"></i>
                                        ) : (
                                          <i className="fa fa-caret-right"></i>
                                        )}{" "}
                                      </div>
                                    </a>
                                  )}
                                  {childrenItem.type === "exteral_link" && (
                                    <>
                                      <Link
                                        to={childrenItem.path}
                                        className={`${
                                          childrenItem.active ? "active" : ""
                                        }`}
                                        onClick={() => {
                                          toggletNavActive(childrenItem);
                                        }}
                                      >
                                        {t(childrenItem.title)}
                                      </Link>
                                    </>
                                  )}
                                  {childrenItem.children && (
                                    <UL
                                      attrUL={{
                                        className:
                                          "simple-list nav-sub-childmenu submenu-content",
                                        style: childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" },
                                      }}
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <LI key={key}>
                                            {childrenSubItem.type ===
                                              "link" && (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {t(childrenSubItem.title)}
                                              </Link>
                                            )}
                                          </LI>
                                        )
                                      )}
                                    </UL>
                                  )}
                                </LI>
                              );
                            })}
                          </UL>
                        </UL>
                      )}
                    </LI>
                  );
                })}
              </Fragment>
            );
          })}
      </UL>
    </Fragment>
  );
};
export default NewSidebarMenuItems;
