import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShowError, ShowSucess } from "../../_helper/helper";
import {
  GET_LEAVESSHAREFORM_DETAILS,
  LEAVESHAREFORM_DELETE,
  LEAVESHAREFORM_UPDATE,
  LEAVESHAREFORM_CREATE,
  LEAVESHAREFORM_GETINFO
} from "./actiontype";
import {
  getleavesformsuccess,
  getleavesformfailure,
  deleteShareFormSuccess,
  deleteShareFormFail,
  updateLeavesFormSuccess,
  updateLeavesFormFail,
  createLeavesFormSuccess,
  createLeavesFormFailure,

  getLeaveDonarInfoSuccess,
  getLeaveDonarInfoFail

} from "./action";
import { getLeaveShareForm, deleteleaveshareform, createleaveshareform, updateleaveshareform, getleaveshareInfo } from "../../_helper/services/EMR/leaveshare";


function* GetLeavesData({ payload: { leaveAppID, LeaveShareID } }) {

  try {
    console.log("leavshareIdaction", leaveAppID, LeaveShareID)
    const response = yield call(getLeaveShareForm, leaveAppID, LeaveShareID);

    yield put(getleavesformsuccess(response));
  } catch (error) {
    yield put(getleavesformfailure(error));
  }
}
function* onGetLeaveShareFormInfo({ payload: EmpID }) {
  try {
    const response = yield call(getleaveshareInfo, EmpID);
    yield put(getLeaveDonarInfoSuccess(response));
    console.log("employee response",response)
  } catch (error) {
    yield put(getLeaveDonarInfoFail(error));
  }
}


function* onCreateLeaveShare({ payload }) {
  try {
    const response = yield call(createleaveshareform, payload);
    yield put(createLeavesFormSuccess(response));
    ShowSucess('Leave Share Created successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(createLeavesFormFailure(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error)

  }
}
function* onUpdateLeaveShare({ payload }) {
  try {
    const response = yield call(updateleaveshareform, payload);
    yield put(updateLeavesFormSuccess(response));
    ShowSucess('Leave Share updated successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(updateLeavesFormFail(error.response));
    console.log(error?.response?.data?.message);
    ShowError(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
}




function* onDeleteLeaveshare({ payload: dID }) {
  try {

    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const response = yield call(deleteleaveshareform, dID);
      yield put(deleteShareFormSuccess(response));
      ShowSucess('Deleted successfully', {
        position: 'top-right'
      });
    }

  } catch (error) {
    yield put(deleteShareFormFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* GetLeaveshareFormSaga() {

  yield takeLatest(GET_LEAVESSHAREFORM_DETAILS, GetLeavesData);
  yield takeLatest(LEAVESHAREFORM_GETINFO, onGetLeaveShareFormInfo);
  yield takeLatest(LEAVESHAREFORM_CREATE, onCreateLeaveShare);
  yield takeLatest(LEAVESHAREFORM_UPDATE, onUpdateLeaveShare);
  yield takeLatest(LEAVESHAREFORM_DELETE, onDeleteLeaveshare);

}

export default GetLeaveshareFormSaga;
