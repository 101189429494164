import {
  GET_DETAILS_BY_ADMIN,
  GET_DETAILS_BY_ADMIN_SUCCESS,
  GET_DETAILS_BY_ADMIN_FAIL,
  PROCESS_CREATE,
  PROCESS_CREATE_FAIL,
  PROCESS_CREATE_SUCCESS,
  DETAILS_BY_ADMIN_UPDATE,
  DETAILS_BY_ADMIN_UPDATE_FAIL,
  DETAILS_BY_ADMIN_UPDATE_SUCCESS,
  GET_TABLENAMES,
  GET_TABLENAMES_SUCCESS,
  GET_TABLENAMES_FAIL,
} from "./actionTypes";

export const getDetailsByAdmin = (payload) => {
  return {
    type: GET_DETAILS_BY_ADMIN,
    payload: payload,
  };
};

export const getDetailsByAdminSuccess = (tableName) => {
  return {
    type: GET_DETAILS_BY_ADMIN_SUCCESS,
    payload: tableName,
  };
};

export const getDetailsByAdminFail = (error) => {
  return {
    type: GET_DETAILS_BY_ADMIN_FAIL,
    payload: error,
  };
};
export const getTableNames = () => {
  return {
    type: GET_TABLENAMES,
  };
};

export const getTableNamesSuccess = (posts) => {
  return {
    type: GET_TABLENAMES_SUCCESS,
    payload: posts,
  };
};

export const getTableNamesFail = (error) => {
  return {
    type: GET_TABLENAMES_FAIL,
    payload: error,
  };
};

export const createDetailsByAdmin = (payload) => {
  return {
    type: PROCESS_CREATE,
    payload: payload,
  };
};
export const createDetailsByAdminSuccess = (response) => {
  return {
    type: PROCESS_CREATE_SUCCESS,
    payload: response,
  };
};
export const createDetailsByAdminFail = (error) => {
  return {
    type: PROCESS_CREATE_FAIL,
    payload: error,
  };
};

export const updateDetailsByAdmin = (payload) => {
  return {
    type: DETAILS_BY_ADMIN_UPDATE,
    payload: payload,
  };
};
export const updateDetailsByAdminSuccess = (response) => {
  return {
    type: DETAILS_BY_ADMIN_UPDATE_SUCCESS,
    payload: response,
  };
};
export const updateDetailsByAdminFail = (error) => {
  return {
    type: DETAILS_BY_ADMIN_UPDATE_FAIL,
    payload: error,
  };
};
