export const GET_DOCUMENT_FIELDS = "GET_DOCUMENT_FIELDS";
export const GET_DOCUMENT_FIELDS_SUCCESS = "GET_DOCUMENT_FIELDS_SUCCESS";
export const GET_DOCUMENT_SOURCES = "GET_DOCUMENT_SOURCES";
export const GET_DOCUMENT_SOURCES_SUCCESS = "GET_DOCUMENT_SOURCES_SUCCESS";
export const POST_ALL_DOCUMENTS = "POST_ALL_DOCUMENTS";
export const POST_ALL_DOCUMENTS_SUCCESS = "POST_ALL_DOCUMENTS_SUCCESS";
export const POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS = "POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS"
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAIL = "GET_DOCUMENT_FAIL";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";
export const RESET = "RESET";
export const POST_ALL_DOCUMENTS_FAIL = "POST_ALL_DOCUMENTS_FAIL";
export const SET_REFERENCEID = "SET_REFERENCEID";
export const GET_DOCUMENT_FIELDS_FAIL = "GET_DOCUMENT_FIELDS_FAIL";
export const GET_DOCUMENT_SOURCES_FAIL = "GET_DOCUMENT_SOURCES_FAIL";
