import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ShowSucess, ShowError } from "../../../_helper/helper";
import { retry } from "redux-saga/effects";
const sliceName = "Transaction";

export const transactionData = createAsyncThunk(
  `${sliceName}/TransactionData`,
  async (payload, status) => {
    console.log("create123456", payload.payload);
    let ggTrackArray = {};
    let data = {};
    let message = "";

    try {
      if (payload.payload !== undefined) {
        try {
          const submitTransactionData = await axios.post(
            `${
              process.env.REACT_APP_API_DOTNET_URL
              // }/GG1/saveTransactions?action=${
            }/GG1Common/saveTransactions?action=${
              payload.staus ? "create" : "update"
            }`,
            payload.payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem(
                  "jwt_access_token"
                )}`,
              },
            }
          );

          if (submitTransactionData.data) {
            console.log("dg", submitTransactionData.data);
            if (
              submitTransactionData.status === 200 ||
              submitTransactionData.status === 201
            ) {
              if (payload.staus == true) {
                data = submitTransactionData.data;
                ggTrackArray = submitTransactionData.data;
              } // message = submitTransactionData.data.message;
              if (payload.staus == false) {
                const msg = submitTransactionData.data.message;
                data = submitTransactionData.data;
                const withoutbr = msg.replace("<br/>", "\n");
                ShowSucess(withoutbr);
              }
            }
            return {
              data: data,
              GGTrackData: ggTrackArray,
              staus: payload.staus,
              message: message,
            };
          }
        } catch (error) {
          if (error?.response?.data?.title) {
            ShowError(error?.response?.data?.title);
          }
        }
      }

      console.log("dg", ggTrackArray);
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const TransactionSlice = createSlice({
  name: sliceName,
  initialState: {
    data: {},
    GGTrackData: {},
    status: "",
    // message: "",
  },
  extraReducers: {
    [transactionData.fulfilled]: (state, action) => {
      console.log("action 1212121 ::::: ==> ", action);
      if (action.payload?.staus === true) {
        state.GGTrackData = action.payload?.GGTrackData;
        state.status = "create";
      } else {
        state.status = "update";
        state.GGTrackData = {};
        // state.message = action.payload.message;
      }
    },
    [transactionData.pending]: (state, action) => {
      // console.log("action 1212121 ::::: ==> ", action, create);
      state.GGTrackData = {};
      state.status = "";
    },
  },
});

export default TransactionSlice.reducer;
