import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
  GET_DETAILS_BY_ADMIN,
  DETAILS_BY_ADMIN_UPDATE,
  GET_TABLENAMES,
} from "./actionTypes";

import {
  getDetailsByAdminSuccess,
  getDetailsByAdminFail,
  updateDetailsByAdminSuccess,
  updateDetailsByAdminFail,
  getTableNamesSuccess,
  getTableNamesFail,
} from "./actions";

import {
  getSystemAdmin,
  updateDetailsByAdmin,
  getTableList,
} from "../../_helper/services/systemAdmin";

function* onGetSystemAdminDetails({ payload }) {
  try {
 
    const response = yield call(getSystemAdmin, payload);
    yield put(getDetailsByAdminSuccess(response));
  } catch (error) {
    yield put(getDetailsByAdminFail(error.response));
  }
}
function* onGetTableNamesList() {
  try {
    const response = yield call(getTableList);
    yield put(getTableNamesSuccess(response));
  } catch (error) {
    yield put(getTableNamesFail(error.response));
  }
}
function* onUpdateDetailsByAdmin({ payload }) {
  try {
    const response = yield call(updateDetailsByAdmin, payload);
    yield put(updateDetailsByAdminSuccess(response));
  } catch (error) {
    yield put(updateDetailsByAdminFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_DETAILS_BY_ADMIN, onGetSystemAdminDetails);
  yield takeLatest(GET_TABLENAMES, onGetTableNamesList);
  yield takeLatest(DETAILS_BY_ADMIN_UPDATE, onUpdateDetailsByAdmin);
}

export default CartSaga;
