import {
	GET_DOCUMENT_FIELDS,
	GET_DOCUMENT_FIELDS_SUCCESS,
	GET_DOCUMENT_SOURCES,
	GET_DOCUMENT_SOURCES_SUCCESS,
	POST_ALL_DOCUMENTS,
	POST_ALL_DOCUMENTS_SUCCESS,
	DELETE_DOCUMENT,
	DELETE_DOCUMENT_SUCCESS,
	DELETE_DOCUMENT_FAIL,
	RESET,
	POST_ALL_DOCUMENTS_FAIL,
	GET_DOCUMENT_FIELDS_FAIL,
	GET_DOCUMENT_SOURCES_FAIL,
	SET_REFERENCEID,
	POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS
} from "./actionTypes";

const initialState = {
	documentFields: [],
	documentSources: [],
	uploadedDocumentAttechment: [],
	loading: false,
	deletedDocument: [],
	loading: false,
	error: {
		message: "",
	},
	documentReferenceId: ""
};

const DocumentUploadReducer = (state = initialState, action) => {
	if (!action) return state;
	switch (action.type) {
		case GET_DOCUMENT_FIELDS:
			state = { ...state, loading: true };
			break;
		case GET_DOCUMENT_FIELDS_SUCCESS:
			state = { ...state, documentFields: action.payload, loading: false };
			break;
		case GET_DOCUMENT_FIELDS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_DOCUMENT_SOURCES:
			state = { ...state, loading: true };
			break;
		case GET_DOCUMENT_SOURCES_SUCCESS:
			state = { ...state, documentSources: action.payload, loading: false };
			break;
		case GET_DOCUMENT_SOURCES_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case POST_ALL_DOCUMENTS:
			state = { ...state, loading: true, };
			break;
		case POST_ALL_DOCUMENTS_SUCCESS:
			state = { ...state, uploadedDocumentAttechment: action.payload, documentReferenceId: action.payload?.data?.referenceId, loading: false };
			break;
		case POST_ALL_DOCUMENTS_HIDE_LOADER_SUCCESS:
			state = { ...state, uploadedDocumentAttechment: action.payload, loading: false };
			break;
		case POST_ALL_DOCUMENTS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case DELETE_DOCUMENT:
			state = { ...state, loading: true, deletedDocument: [] };
			break;
		case DELETE_DOCUMENT_SUCCESS:
			state = {
				...state,
				deletedDocument: action.payload,
				loading: false,
			};
			break;
		case DELETE_DOCUMENT_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case SET_REFERENCEID:
			state = { ...state, documentReferenceId: action.payload };
			break;
		case RESET:
			state = { ...initialState };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default DocumentUploadReducer;
