import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
  GET_PROCESSES,
  GET_PROCESSES_DETAILS,
  PROCESS_COPY,
  PROCESS_CREATE,
  PROCESS_DELETE,
  PROCESS_UPDATE,
  PROCESS_STATUS_CREATE,
  PROCESS_STATUS_DELETE,
  PROCESS_STATUS_UPDATE,
  GET_PROCESSES_STATUSLIST,
} from "./actionTypes";

import {
  getProcessesSuccess,
  getProcessesFail,
  getProcessesDetailsSuccess,
  getProcessesDetailsFail,
  createProcessSuccess,
  createProcessFail,
  updateProcessSuccess,
  updateProcessFail,
  deleteProcessSuccess,
  deleteProcessFail,
  copyProcessSuccess,
  copyProcessFail,
  createProcessStatusSuccess,
  createProcessStatusFail,
  updateProcessStatusSuccess,
  updateProcessStatusFail,
  deleteProcessStatusSuccess,
  deleteProcessStatusFail,
  getProcessesStatusListSuccess,
  getProcessesStatusListFail,
} from "./actions";

import {
  copyProcess,
  createProcess,
  deleteProcess,
  getProcessDetails,
  getProcesses,
  updateProcess,
  updateProcessStatus,
  deleteProcessStatus,
  createProcessStatus,
  updateProcessStatusList,
  getProcessStatusList,
} from "../../_helper/services/process";
function* onGetProcesses({ payload: { page, pageSize } }) {
  try {
    const response = yield call(getProcesses, { page, pageSize });
    yield put(getProcessesSuccess(response));
  } catch (error) {
    yield put(getProcessesFail(error.response));
  }
}

function* onGetProcessDetails({ payload: id }) {
  try {
    const response = yield call(getProcessDetails, id);
    yield put(getProcessesDetailsSuccess(response));
  } catch (error) {
    yield put(getProcessesDetailsFail(error.response));
  }
}
function* onGetProcessStatusList({ payload: id }) {
  try {
    const response = yield call(getProcessStatusList, id);
    yield put(getProcessesStatusListSuccess(response));
  } catch (error) {
    yield put(getProcessesStatusListFail(error.response));
  }
}
function* onCreateProcess({ payload }) {
  try {
    const response = yield call(createProcess, payload);
    yield put(createProcessSuccess(response));
    // ShowSucess('Process Created successfully', {
    //   position: 'top-right'
    // });
  } catch (error) {
    yield put(createProcessFail(error.response));
  }
}
function* onCreateProcessStatus({ payload }) {
  try {
    const response = yield call(createProcessStatus, payload);
    yield put(createProcessStatusSuccess(response));
    ShowSucess("Process Status Created successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(createProcessStatusFail(error.response));
  }
}
function* onUpdateProcess({ payload: { id }, payload }) {
  try {
    const response = yield call(updateProcess, id, payload);
    yield put(updateProcessSuccess(response));
  } catch (error) {
    yield put(updateProcessFail(error.response));
  }
}
function* onUpdateProcessStatus({ payload }) {
  try {
    const response = payload.statusList
      ? yield call(updateProcessStatusList, payload.statusList)
      : yield call(updateProcessStatus, payload.id, payload);
    yield put(updateProcessStatusSuccess(response));
  } catch (error) {
    yield put(updateProcessStatusFail(error.response));
  }
}
function* onCopyProcess({ payload: { id }, payload }) {
  try {
    const response = yield call(copyProcess, id, payload);
    yield put(copyProcessSuccess(response));
  } catch (error) {
    yield put(copyProcessFail(error.response));
  }
}
function* onDeleteProcess({ payload: id }) {
  try {
    const response = yield call(deleteProcess, id);
    yield put(deleteProcessSuccess(response));
    // ShowSucess('Deleted successfully', {
    //   position: 'top-right'
    // });
  } catch (error) {
    yield put(deleteProcessFail(error.response));
  }
}

function* onDeleteProcessStatus({ payload: id }) {
  try {
    const response = yield call(deleteProcessStatus, id);
    yield put(deleteProcessStatusSuccess(response));
    ShowSucess("Deleted successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(deleteProcessStatusFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_PROCESSES, onGetProcesses);
  yield takeLatest(GET_PROCESSES_STATUSLIST, onGetProcessStatusList);
  yield takeLatest(GET_PROCESSES_DETAILS, onGetProcessDetails);
  yield takeLatest(PROCESS_CREATE, onCreateProcess);
  yield takeLatest(PROCESS_STATUS_CREATE, onCreateProcessStatus);
  yield takeLatest(PROCESS_UPDATE, onUpdateProcess);
  yield takeLatest(PROCESS_COPY, onCopyProcess);
  yield takeLatest(PROCESS_STATUS_UPDATE, onUpdateProcessStatus);
  yield takeLatest(PROCESS_DELETE, onDeleteProcess);
  yield takeLatest(PROCESS_STATUS_DELETE, onDeleteProcessStatus);
}

export default CartSaga;
