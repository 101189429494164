export const SEARCH_EMPLOYEE = "SEARCH_EMPLOYEE";
export const SEARCH_EMPLOYEE_SUCCESS = "SEARCH_EMPLOYEE_SUCCESS";
export const SEARCH_EMPLOYEE_FAIL = "SEARCH_EMPLOYEE_FAIL";
export const FIND_EMPLOYEE = "FIND_EMPLOYEE";
export const FIND_EMPLOYEE_SUCCESS = "FIND_EMPLOYEE_SUCCESS";
export const FIND_EMPLOYEE_FAIL = "FIND_EMPLOYEE_FAIL";
export const GET_EMPLOYEE_DEDUCTION = "GET_EMPLOYEE_DEDUCTION";
export const GET_EMPLOYEE_DEDUCTION_SUCCESS = "GET_EMPLOYEE_DEDUCTION_SUCCESS";
export const GET_EMPLOYEE_DEDUCTION_FAIL = "GET_EMPLOYEE_DEDUCTION_FAIL";
export const UPDATE_DEDUCTION = "UPDATE_DEDUCTION";
export const UPDATE_DEDUCTION_SUCCESS = "UPDATE_DEDUCTION_SUCCESS";
export const UPDATE_DEDUCTION_FAIL = "UPDATE_DEDUCTION_FAIL";
export const GET_EMPLOYEE_TYPES = "GET_EMPLOYEE_TYPES";
export const GET_EMPLOYEE_TYPES_SUCCESS = "GET_EMPLOYEE_TYPES_SUCCESS";
export const GET_EMPLOYEE_TYPES_FAIL = "GET_EMPLOYEE_TYPES_FAIL";
export const UPDATE_CERTIFICATIONS = "UPDATE_CERTIFICATIONS";
export const UPDATE_CERTIFICATIONS_SUCCESS = "UPDATE_CERTIFICATIONS_SUCCESS";
export const UPDATE_CERTIFICATIONS_FAIL = "UPDATE_CERTIFICATIONS_FAIL";
export const CREATE_CERTIFICATIONS = "CREATE_CERTIFICATIONS";
export const CREATE_CERTIFICATIONS_SUCCESS = "CREATE_CERTIFICATIONS_SUCCESS";
export const CREATE_CERTIFICATIONS_FAIL = "CREATE_CERTIFICATIONS_FAIL";
export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAIL = "CREATE_TAG_FAIL";
export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAIL = "UPDATE_TAG_FAIL";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";
export const EMPLOYEE = "GET_EMPLOYEE_";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";
export const GET_DOCUMENT_ATTACHED = "GET_DOCUMENT_ATTACHED";
export const GET_DOCUMENT_ATTACHED_SUCCESS = "GET_DOCUMENT_ATTACHED_SUCCESS";
export const GET_DOCUMENT_ATTACHED_FAIL = "GET_DOCUMENT_ATTACHED_FAIL";
export const SUBSCRIBE_DEDUCTION = "EMPLOYEE_SUBSCRIBE_DEDUCTION";
export const SUBSCRIBE_DEDUCTION_SUCCESS =
  "EMPLOYEE_SUBSCRIBE_DEDUCTION_SUCCESS";
export const SUBSCRIBE_DEDUCTION_FAIL = "EMPLOYEE_SUBSCRIBE_DEDUCTION_FAIL";

export const UNSUBSCRIBE_DEDUCTION = "EMPLOYEE_UNSUBSCRIBE_DEDUCTION";
export const UNSUBSCRIBE_DEDUCTION_SUCCESS =
  "EMPLOYEE_UNSUBSCRIBE_DEDUCTION_SUCCESS";
export const UNSUBSCRIBE_DEDUCTION_FAIL = "EMPLOYEE_UNSUBSCRIBE_DEDUCTION_FAIL";

export const GET_EMPLOYEE_TRANSACTIONLOGS = "GET_EMPLOYEE_TRANSACTIONLOGS";
export const GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS =
  "GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS";
export const GET_EMPLOYEE_TRANSACTIONLOGS_FAIL =
  "GET_EMPLOYEE_TRANSACTIONLOGS_FAIL";

export const GET_EMPLOYEE_TRANSACTIONINFO = "GET_EMPLOYEE_TRANSACTIONINFO";
export const GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS =
  "GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS";
export const GET_EMPLOYEE_TRANSACTIONINFO_FAIL =
  "GET_EMPLOYEE_TRANSACTIONINFO_FAIL";
export const AUDIT_TRAIL_HISTORY = "AUDIT_TRAIL_HISTORY";
export const AUDIT_TRAIL_HISTORY_SUCCESS = "AUDIT_TRAIL_HISTORY_SUCCESS";
export const AUDIT_TRAIL_HISTORY_FAIL = "AUDIT_TRAIL_HISTORY_FAIL";

export const EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_FAIL = "EMPLOYEE_LIST_FAIL";