import React from "react";
import { TextField } from "@mui/material";
const CustomTextField = (props) => {
  const {
    value,
    className,
    validate,
    type,
    InputProps,
    inputProps,
    name,
    defaultValue,
    label,
    placeholder,
    onChange,
    shrink,
    onKeyDown,
    onKeyUp,
    checkHighlightForTextBox,
    checkHighlightSysGenForTextBox,
    hasError,
    events = {},
  } = props;
  //   const { onChange, ...restEvents } = events;
  const textFieldColor = "#00000092";
  const textFieldColor1 = "#00000082";

  const textFieldSX = {
    input: {
      "-webkit-text-fill-color": `${textFieldColor1} !important`,
      color: `${textFieldColor1} !important`,
    },
    label: {
      "-webkit-text-fill-color": `${textFieldColor} !important`,
    },
  };
  return (
    <TextField
      margin="dense"
      // sx={textFieldSX}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#00000092",
        },
        "& .MuiFormLabel-root.Mui-disabled": {
          WebkitTextFillColor: "#00000092",
        },
      }}
      className={`flex w-full mx-10 ${
        checkHighlightForTextBox
          ? "isHL"
          : checkHighlightSysGenForTextBox
          ? "isHL1"
          : ""
      } `}
      variant="outlined"
      style={{ width: "100%" }}
      size="small"
      disabled={hasError ? false : true}
      inputProps={
        ({
          style: {
            fontSize: "14px",
            paddingTop: "10px",
            width: "100%",
          },
        },
        inputProps)
      }
      // inputProps={
      //   (inputProps,
      //   {
      //     style: {
      //       fontSize: "14px",
      //       paddingTop: "10px",
      //       width: "100%",
      //     },
      //   })
      // }
      InputLabelProps={{ shrink: shrink ? shrink : false }}
      InputProps={InputProps}
      label={label}
      name={name}
      type={type}
      value={value}
      error={hasError}
      validate={validate}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={"off"}
    />
  );
};
export default CustomTextField;
