import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import { useAuth } from 'oidc-react';

const LayoutRoutes = () => {

	const {userData} = useAuth();
 
  return (
    <>
      <Routes>
        {routes.filter(d=> {
			return (d.roles && userData?.profile?.role && d.roles.indexOf(userData?.profile?.role) > -1) || d.roles === undefined
		}).map(({ path,layout, Component }, i) => {
			
			if(layout === 'none') {
				return <Route key={i}>
				<Route path={path}  element={Component}  />
         	</Route>
			} else {
				// console.log(path,layout);
				return <Route element={<AppLayout />} key={i}>
				<Route path={path}  element={Component}  />
         	</Route>;
			}
			 
		})}
      </Routes>
    </>
  );
};

export default LayoutRoutes;