import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAIL,
  GET_WORKFLOWS_DETAILS,
  GET_WORKFLOWS_DETAILS_SUCCESS,
  GET_WORKFLOWS_DETAILS_FAIL,
  WORKFLOW_CREATE,
  WORKFLOW_CREATE_FAIL,
  WORKFLOW_CREATE_SUCCESS,
  WORKFLOW_DELETE,
  WORKFLOW_DELETE_FAIL,
  WORKFLOW_DELETE_SUCCESS,
  WORKFLOW_UPDATE,
  WORKFLOW_UPDATE_FAIL,
  WORKFLOW_UPDATE_SUCCESS,
  GET_WORKFLOWS_SETTING,
  GET_WORKFLOWS_SETTING_SUCCESS,
  GET_WORKFLOWS_SETTING_FAIL,
  SAVE_WORKFLOWS_SETTING,
  SAVE_WORKFLOWS_SETTING_SUCCESS,
  SAVE_WORKFLOWS_SETTING_FAIL,
  WORKFLOW_COPY,
  WORKFLOW_COPY_SUCCESS,
  WORKFLOW_COPY_FAIL,
} from "./actionTypes";

const initialState = {
  workflows: [],
  workflowDetails: {},
  postingResult: {},
  workflowCreated: {},
  loadingWorkflows: false,
  loadingWorkflowDetails: false,
  workflowSetting: {},
  loadingWorkflowSetting: false,
  posting: false,
  error: {
    message: "",
  },
};

const PostReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_WORKFLOWS:
      state = { ...state, loadingWorkflows: true };
      break;
    case GET_WORKFLOWS_SUCCESS:
      state = { ...state, workflows: action.payload, loadingWorkflows: false };
      break;
    case GET_WORKFLOWS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingWorkflows: false,
      };
      break;
    case GET_WORKFLOWS_DETAILS:
      state = { ...state, loadingWorkflowDetails: true };
      break;
    case GET_WORKFLOWS_DETAILS_SUCCESS:
      state = {
        ...state,
        workflowDetails: action.payload,
        loadingWorkflowDetails: false,
      };
      break;
    case GET_WORKFLOWS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingWorkflowDetails: false,
      };
      break;
    case WORKFLOW_CREATE:
      state = { ...state, workflowCreated: {}, posting: true };
      break;
    case WORKFLOW_CREATE_SUCCESS:
      state = { ...state, workflowCreated: action.payload, posting: false };
      break;
    case WORKFLOW_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        workflowCreated: {},
        posting: false,
      };
      break;
    case WORKFLOW_UPDATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case WORKFLOW_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case WORKFLOW_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case WORKFLOW_COPY:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case WORKFLOW_COPY_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case WORKFLOW_COPY_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case WORKFLOW_DELETE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case WORKFLOW_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case WORKFLOW_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case GET_WORKFLOWS_SETTING:
      state = { ...state, loadingWorkflowSetting: true };
      break;
    case GET_WORKFLOWS_SETTING_SUCCESS:
      state = {
        ...state,
        workflowSetting: action.payload,
        loadingWorkflowSetting: false,
      };
      break;
    case GET_WORKFLOWS_SETTING_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingWorkflowSetting: false,
      };
      break;
    case SAVE_WORKFLOWS_SETTING:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case SAVE_WORKFLOWS_SETTING_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case SAVE_WORKFLOWS_SETTING_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostReducer;
