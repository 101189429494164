// import { put } from "redux-saga/effects";
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getChartDataForYearOfService = (queryname) => get(`${baseURL}${url.GET_CHART_DATA_YEAR_SERVICE}/${queryname}`);
export const getChartDataForAgeGroup = (queryname) => get(`${baseURL}${url.GET_CHART_DATA_AGE_GROUP}/${queryname}`);
export const getChartDataForSalaryGroup = (queryname) => get(`${baseURL}${url.GET_CHART_DATA_SALARY_GROUP}/${queryname}`);

