export const GET_DETAILS_BY_ADMIN = "GET_DETAILS_BY_ADMIN";
export const GET_DETAILS_BY_ADMIN_SUCCESS = "GET_DETAILS_BY_ADMIN_SUCCESS";
export const GET_DETAILS_BY_ADMIN_FAIL = "GET_DETAILS_BY_ADMIN_FAIL";
export const GET_TABLENAMES = "GET_TABLENAMES";
export const GET_TABLENAMES_SUCCESS = "GET_TABLENAMES_SUCCESS";
export const GET_TABLENAMES_FAIL = "GET_TABLENAMES_FAIL";

export const GET_DETAILS_BY_ADMIN_SETTING = "GET_DETAILS_BY_ADMIN_SETTING";
export const GET_DETAILS_BY_ADMIN_SETTING_SUCCESS =
  "GET_DETAILS_BY_ADMIN_SETTING_SUCCESS";
export const GET_DETAILS_BY_ADMIN_SETTING_FAIL =
  "GET_DETAILS_BY_ADMIN_SETTING_FAIL";

export const SAVE_PROCESSES_SETTING = "SAVE_PROCESSES_SETTING";
export const SAVE_PROCESSES_SETTING_SUCCESS = "SAVE_PROCESSES_SETTING_SUCCESS";
export const SAVE_PROCESSES_SETTING_FAIL = "SAVE_PROCESSES_SETTING_FAIL";

export const GET_DETAILS_BY_ADMIN_DETAILS = "GET_DETAILS_BY_ADMIN_DETAILS";
export const GET_DETAILS_BY_ADMIN_DETAILS_SUCCESS =
  "GET_DETAILS_BY_ADMIN_DETAILS_SUCCESS";
export const GET_DETAILS_BY_ADMIN_DETAILS_FAIL =
  "GET_DETAILS_BY_ADMIN_DETAILS_FAIL";

export const DETAILS_BY_ADMIN_UPDATE = "DETAILS_BY_ADMIN_UPDATE";
export const DETAILS_BY_ADMIN_UPDATE_SUCCESS =
  "DETAILS_BY_ADMIN_UPDATE_SUCCESS";
export const DETAILS_BY_ADMIN_UPDATE_FAIL = "DETAILS_BY_ADMIN_UPDATE_FAIL";

export const PROCESS_COPY = "PROCESS_COPY";
export const PROCESS_COPY_SUCCESS = "PROCESS_COPY_SUCCESS";
export const PROCESS_COPY_FAIL = "PROCESS_COPY_FAIL";

export const PROCESS_CREATE = "PROCESS_CREATE";
export const PROCESS_CREATE_SUCCESS = "PROCESS_CREATE_SUCCESS";
export const PROCESS_CREATE_FAIL = "PROCESS_CREATE_FAIL";

export const PROCESS_DELETE = "PROCESS_DELETE";
export const PROCESS_DELETE_SUCCESS = "PROCESS_DELETE_SUCCESS";
export const PROCESS_DELETE_FAIL = "PROCESS_DELETE_FAIL";

export const PROCESS_STATUS_CREATE = "PROCESS_STATUS_CREATE";
export const PROCESS_STATUS_CREATE_SUCCESS = "PROCESS_STATUS_CREATE_SUCCESS";
export const PROCESS_STATUS_CREATE_FAIL = "PROCESS_STATUS_CREATE_FAIL";

export const PROCESS_STATUS_UPDATE = "PROCESS_STATUS_UPDATE";
export const PROCESS_STATUS_UPDATE_SUCCESS = "PROCESS_STATUS_UPDATE_SUCCESS";
export const PROCESS_STATUS_UPDATE_FAIL = "PROCESS_STATUS_UPDATE_FAIL";

export const PROCESS_STATUS_DELETE = "PROCESS_STATUS_DELETE";
export const PROCESS_STATUS_DELETE_SUCCESS = "PROCESS_STATUS_DELETE_SUCCESS";
export const PROCESS_STATUS_DELETE_FAIL = "PROCESS_STATUS_DELETE_FAIL";
