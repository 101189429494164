import { put } from "redux-saga/effects";
import { get, post } from "../api_helper";
import * as url from "../url_helper";


export const getEmployee =(id)=> get(`${url.EMPLOYEE}/${id}`);
export const getEmployeeTypes =()=> get(url.EMPLOYEE_TYPES);
//Post
export const advanceSearch = (payload) => post(url.ADVANCE_SEARCH, payload);
export const loadColumns = (tableName) => get(url.ADVANCE_SEARCH_LOAD_COLUMNS+'/'+tableName);
export const performNewSubscription = (payload) => post(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION+'/subscribe', payload);
export const performActivateSubscription = (DeductionCode) => put(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION+'/activate/'+DeductionCode);
export const performInActivateSubscription = (DeductionCode) => put(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION+'/deactivate/'+DeductionCode);
export const findAsyouType = (table,keyField,valueField,search) => get(url.ADVANCE_SEARCH_LOAD_FINDASTYPE+'/'+`${table}/${keyField}/${valueField}/${search}`);
//Post
export const getDeductionPlan = (id) =>
  get(url.EMPLOYEE_DEDUCTION, {
    params: {
      id: id,
    },
 });