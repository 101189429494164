import {
  SEARCH_EMPLOYEE,
  SEARCH_EMPLOYEE_SUCCESS,
  SEARCH_EMPLOYEE_FAIL,
  GET_EMPLOYEE_DEDUCTION,
  GET_EMPLOYEE_DEDUCTION_SUCCESS,
  GET_EMPLOYEE_DEDUCTION_FAIL,
  GET_EMPLOYEE_TYPES,
  GET_EMPLOYEE_TYPES_FAIL,
  GET_EMPLOYEE_TYPES_SUCCESS,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  FIND_EMPLOYEE,
  EMPLOYEE,
  EMPLOYEE_SUCCESS,
  SUBSCRIBE_DEDUCTION,
  SUBSCRIBE_DEDUCTION_SUCCESS,
  SUBSCRIBE_DEDUCTION_FAIL,
  UNSUBSCRIBE_DEDUCTION,
  UNSUBSCRIBE_DEDUCTION_SUCCESS,
  UNSUBSCRIBE_DEDUCTION_FAIL,
  GET_EMPLOYEE_TRANSACTIONLOGS,
  GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS,
  GET_EMPLOYEE_TRANSACTIONLOGS_FAIL,
  GET_EMPLOYEE_TRANSACTIONINFO,
  GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS,
  GET_EMPLOYEE_TRANSACTIONINFO_FAIL,
  AUDIT_TRAIL_HISTORY,
  AUDIT_TRAIL_HISTORY_SUCCESS,
  FIND_EMPLOYEE_SUCCESS,
  FIND_EMPLOYEE_FAIL,
  CREATE_CERTIFICATIONS,
  CREATE_CERTIFICATIONS_SUCCESS,
  CREATE_CERTIFICATIONS_FAIL,
  UPDATE_CERTIFICATIONS,
  UPDATE_CERTIFICATIONS_SUCCESS,
  UPDATE_CERTIFICATIONS_FAIL,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  GET_DOCUMENT_ATTACHED,
  GET_DOCUMENT_ATTACHED_SUCCESS,
  GET_DOCUMENT_ATTACHED_FAIL,
  EMPLOYEE_LIST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_FAIL,
} from "./actionTypes";

const initialState = {
  employeeSearched: [],
  empDuductionPlans: [],
  transactionLogs: [],
  transactionInfo: {},
  empTypes: [],
  document: [],
  findEmployee: {},
  loading: false,
  createObject: {},
  updateObject: {},
  employeeAuditHistory: [],
  employee: {},
  loadingEmployeeSearch: false,
  loadingDeductionPlans: false,
  loadingEmployeeTypes: false,
  loadingEmployee: false,
  loadingPerformSubscription: false,
  error: {
    message: "",
  },
  employeeList: {},
  employeeLists: [],
  loadingEmployeeLists: false
};

const EmployeeReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case SEARCH_EMPLOYEE:
      state = { ...state, loadingEmployeeSearch: true };
      break;
    case SEARCH_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        employeeSearched: action.payload,
        loadingEmployeeSearch: false,
      };
      break;
    case SEARCH_EMPLOYEE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingEmployeeSearch: false,
      };
      break;
    case GET_EMPLOYEE_DEDUCTION:
      state = { ...state, loadingDeductionPlans: true };
      break;
    case GET_EMPLOYEE_DEDUCTION_SUCCESS:
      state = {
        ...state,
        empDuductionPlans: action.payload,
        loadingDeductionPlans: false,
      };
      break;
    case GET_EMPLOYEE_DEDUCTION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDeductionPlans: false,
      };
      break;
    case GET_EMPLOYEE_TYPES:
      state = { ...state, loadingEmployeeTypes: true };
      break;
    case GET_EMPLOYEE_TYPES_SUCCESS:
      state = {
        ...state,
        empTypes: action.payload,
        loadingEmployeeTypes: false,
      };
      break;

    case GET_EMPLOYEE_TYPES_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDeductionPlans: false,
      };
      break;
    case CREATE_CERTIFICATIONS:
      state = { ...state, createObject: {}, loading: true };
      break;
    case CREATE_CERTIFICATIONS_SUCCESS:
      state = {
        ...state,
        createObject: action.payload,
        loading: false,
      };
      break;

    case CREATE_CERTIFICATIONS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        createObject: {},
        loading: false,
      };
      break;
    case UPDATE_CERTIFICATIONS:
      state = { ...state, updateObject: {}, loading: true };
      break;
    case UPDATE_CERTIFICATIONS_SUCCESS:
      state = {
        ...state,
        updateObject: action.payload,
        loading: false,
      };
      break;

    case UPDATE_CERTIFICATIONS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        updateObject: {},
        loading: false,
      };
      break;
    case CREATE_TAG:
      state = { ...state, createObject: {}, loading: true };
      break;
    case CREATE_TAG_SUCCESS:
      state = {
        ...state,
        createObject: action.payload,
        loading: false,
      };
      break;

    case CREATE_TAG_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        createObject: {},
        loading: false,
      };
      break;
    case UPDATE_TAG:
      state = { ...state, updateObject: {}, loading: true };
      break;
    case UPDATE_TAG_SUCCESS:
      state = {
        ...state,
        updateObject: action.payload,
        loading: false,
      };
      break;

    case UPDATE_TAG_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        updateObject: {},
        loading: false,
      };
      break;
    case AUDIT_TRAIL_HISTORY:
      return { ...state };
    case AUDIT_TRAIL_HISTORY_SUCCESS:
      return { ...state, employeeAuditHistory: [...action.response] };
    case GET_EMPLOYEE:
      state = { ...state, employee: {}, loadingEmployee: true };
      break;
    case GET_EMPLOYEE_SUCCESS:
      state = { ...state, employee: action.payload, loadingEmployee: false };
      break;
    case GET_EMPLOYEE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        employee: {},
        loadingEmployee: false,
      };
      break;
    case FIND_EMPLOYEE:
      state = { ...state, findEmployee: {}, loadingEmployee: true };
      break;
    case FIND_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        findEmployee: action.payload,
        loadingEmployee: false,
      };
      break;
    case FIND_EMPLOYEE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingEmployee: false,
      };
      break;
    case GET_DOCUMENT_ATTACHED:
      state = {
        ...state,
        document: [],
        loading: true,
      };
      break;
    case GET_DOCUMENT_ATTACHED_SUCCESS:
      state = {
        ...state,
        document: action.payload,
        loading: false,
      };
      break;
    case GET_DOCUMENT_ATTACHED_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case EMPLOYEE:
      return { ...state };

    case EMPLOYEE_SUCCESS:
      console.log("action", action);
      return { ...state, employeeList: { ...action.payload } };
    case SUBSCRIBE_DEDUCTION:
      state = { ...state, loadingPerformSubscription: true };
      break;
    case SUBSCRIBE_DEDUCTION_SUCCESS:
      state = {
        ...state,
        employee: action.payload[0],
        loadingPerformSubscription: false,
      };
      break;
    case SUBSCRIBE_DEDUCTION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPerformSubscription: false,
      };
      break;
    case UNSUBSCRIBE_DEDUCTION:
      state = { ...state, loadingPerformSubscription: true };
      break;
    case UNSUBSCRIBE_DEDUCTION_SUCCESS:
      state = {
        ...state,
        employee: action.payload[0],
        loadingPerformSubscription: false,
      };
      break;
    case UNSUBSCRIBE_DEDUCTION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPerformSubscription: false,
      };
      break;
    case GET_EMPLOYEE_TRANSACTIONLOGS:
      state = { ...state, transactionLogs: [], loadingTransaction: true };
      break;
    case GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS:
      state = {
        ...state,
        transactionLogs: action.payload,
        loadingTransaction: false,
      };
      break;
    case GET_EMPLOYEE_TRANSACTIONLOGS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingTransaction: false,
      };
      break;
    case GET_EMPLOYEE_TRANSACTIONINFO:
      state = { ...state, transactionInfo: {}, loadingTransaction: true };
      break;
    case GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS:
      state = {
        ...state,
        transactionInfo: action.payload,
        loadingTransaction: false,
      };
      break;
    case GET_EMPLOYEE_TRANSACTIONINFO_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingTransaction: false,
      };
      break;

      case EMPLOYEE_LIST:
      state = { ...state, employeeLists: [], loadingEmployeeLists: true };
      break;
    case EMPLOYEE_LIST_SUCCESS:
      state = { ...state, employeeLists: action.payload, loadingEmployeeLists: false };
      break;
    case EMPLOYEE_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        employeeLists: [],
        loadingEmployeeLists: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EmployeeReducer;
