import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "EmplFrPerformanceEvalSlice";
export const fetchEmplFrPerformanceEvalData = createAsyncThunk(
  `${sliceName}/fetchEmployeeData`,
  async (actnNo) => {
    try {
      //get DPDV

      //get nature of transactions

      let employeeData = {};

      try {
        const LookUpItems = await axios.get(
          `${process.env.REACT_APP_API_URL}/employee/${actnNo}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        if (LookUpItems) {
          employeeData = LookUpItems.data;
        }
        // if (LookUpItems) {
        //   LookUpItems.data.map((item) => {
        //     employeeData.push(item);
        //   });
        // }
      } catch (error) {
        console.log("error", error);
      }

      return {
        employeeData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const fetchEmplDataByIDPerformanceEval = createAsyncThunk(
  `${sliceName}/fetchEmployeeDataByID`,
  async (id) => {
    try {
      //get DPDV

      //get nature of transactions

      let employeeDatabyId = {};
      try {
        const LookUpItems = await axios.get(
          `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/${id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        console.log("data", LookUpItems);
        if (LookUpItems) {
          employeeDatabyId = LookUpItems.data;
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        employeeDatabyId,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getUrlToken = createAsyncThunk(
  `${sliceName}/getToken`,
  async (id) => {
    try {
      // Get Token
      let token = "";

      try {
        const getToken = await axios.get(
          `https://hr-webapi.dev.ehrpweb.com/getToken`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        token = getToken?.data;
      } catch (error) {
        console.log("error", error);
      }

      return {
        token,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getAuditHistoryData = createAsyncThunk(
  `${sliceName}/getAuditHistoryData`,
  async (data) => {
    try {
      const getAuditHistoryByActionNo = await axios.get(
        `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/AuditTrail/${data.employeeid}/${data.hrControl}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let HistoryData;
      console.log("dfsdfsadfsadfsdfsdaf", getAuditHistoryByActionNo.data);
      if (getAuditHistoryByActionNo.data) {
        HistoryData = getAuditHistoryByActionNo.data;
      }
      return {
        HistoryData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const EmplFrPerformanceEvalSlice = createSlice({
  name: sliceName,
  initialState: {
    EmployeeArr: {},
    EmployeeDatabyId: {},
    token: "",
    loding: false,
    auditHistory: [],
  },
  extraReducers: {
    [fetchEmplFrPerformanceEvalData.fulfilled]: (state, action) => {
      state.EmployeeArr = action.payload.employeeData;
      // state.loding = false;
    },
    [fetchEmplDataByIDPerformanceEval.pending]: (state, action) => {
      state.EmployeeDatabyId = {};
      state.loding = true;
    },
    [fetchEmplDataByIDPerformanceEval.fulfilled]: (state, action) => {
      state.EmployeeDatabyId = action.payload.employeeDatabyId;
      state.loding = false;
    },
    [fetchEmplDataByIDPerformanceEval.rejected]: (state, action) => {
      state.loding = false;
    },
    [fetchEmplFrPerformanceEvalData.pending]: (state, action) => {
      state.EmployeeArr = {};
      // state.loding = true;
    },
    [fetchEmplFrPerformanceEvalData.rejected]: (state, action) => {
      // state.loding = false;
    },
    [getUrlToken.pending]: (state, action) => {
      state.loding = true;
    },
    [getUrlToken.fulfilled]: (state, action) => {
      state.token = action.payload.token;
      state.loding = false;
    },
    [getUrlToken.rejected]: (state, action) => {
      state.loding = false;
    },
    [getAuditHistoryData.fulfilled]: (state, action) => {
      state.auditHistory = action.payload.HistoryData;
      state.loding = false;
    },
    [getAuditHistoryData.pending]: (state, action) => {
      state.auditHistory = {};
      state.loding = true;
    },
    [getAuditHistoryData.rejected]: (state, action) => {
      state.auditHistory = {};
      state.loding = false;
    },
  },
});

export default EmplFrPerformanceEvalSlice.reducer;
