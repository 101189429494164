export const ADVANCE_SEARCH = "ADVANCE_SEARCHV2";
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCHV2_SUCCESS";
export const ADVANCE_SEARCH_FAIL = "ADVANCE_SEARCHV2_FAIL";
export const SAVE_QUERY = "SAVE_QUERY";
export const LOAD_COLUMNS = "ADVANCE_SEARCHV2_LOAD_COLUMNS";
export const LOAD_COLUMNS_SUCCESS = "ADVANCE_SEARCHV2_LOAD_COLUMNS_SUCCESS";
export const LOAD_COLUMNS_FAIL = "ADVANCE_SEARCHV2_LOAD_COLUMNS_FAIL";
export const GG1_EXCEL_REPORT = "GG1_EXCEL_REPORT";
export const GG1_EXCEL_REPORT_SUCCESS = "GG1_EXCEL_REPORT_SUCCESS";
export const GG1_EXCEL_REPORT_FAIL = "GG1_EXCEL_REPORT_FAIL";
export const FINDASYOUTYPE = "FINDASYOUTYPEV2";
export const FINDASYOUTYPE_SUCCESS = "FINDASYOUTYPEV2_SUCCESS";
export const FINDASYOUTYPE_FAIL = "FINDASYOUTYPEV2_FAIL";

export const SAVE_ADVANCE_SEARCH = "SAVE_ADVANCE_SEARCH";
export const SAVE_ADVANCE_SEARCH_SUCCESS = "SAVE_ADVANCE_SEARCH_SUCCESS";
export const SAVE_ADVANCE_SEARCH_FAIL = "SAVE_ADVANCE_SEARCH_FAIL";
export const DELETE_SAVED_QUERY = "DELETE_SAVED_QUERY";
export const DELETE_SAVED_QUERY_SUCCESS = "DELETE_SAVED_QUERY_SUCCESS";
export const DELETE_SAVED_QUERY_FAIL = "DELETE_SAVED_QUERY_FAIL";
export const GET_ADVANCE_SEARCH_BYID = "GET_ADVANCE_SEARCH_BYID";
export const GET_ADVANCE_SEARCH_BYID_SUCCESS =
  "GET_ADVANCE_SEARCH_BYID_SUCCESS";
export const GET_ADVANCE_SEARCH_BYID_FAIL = "GET_ADVANCE_SEARCH_BYID_FAIL";

export const GET_ALL_ADVANCE_SEARCH = "GET_ALL_ADVANCE_SEARCH";
export const GET_ALL_ADVANCE_SEARCH_SUCCESS = "GET_ALL_ADVANCE_SEARCH_SUCCESS";
export const GET_ALL_ADVANCE_SEARCH_FAIL = "GET_ALL_ADVANCE_SEARCH_FAIL";
export const SAVE_LOCATION = "SAVE_LOCATION";
