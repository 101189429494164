import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADVANCE_SEARCH,
  LOAD_COLUMNS,
  FINDASYOUTYPE,
  SAVE_ADVANCE_SEARCH,
  GET_ADVANCE_SEARCH_BYID,
  GET_ALL_ADVANCE_SEARCH,
  DELETE_SAVED_QUERY,
  GG1_EXCEL_REPORT,
} from "./actionTypes";

import {
  advanceSearchSuccess,
  advanceSearchFail,
  loadColumnsSuccess,
  loadColumnsFail,
  findAsyouTypeSuccess,
  findAsYouTypeFail,
  saveAdvanceSearchSuccess,
  saveAdvanceSearchFail,
  getAdvanceSearchByIdSuccess,
  getAdvanceSearchByIdFail,
  getAllAdvanceSearchSuccess,
  getAllAdvanceSearchFail,
  deleteSearchSaveQuerySuccess,
  deleteSearchSaveQueryFail,
  gg1ExcelReportSuccess,
  gg1ExcelReportFail,
} from "./actions";

import {
  advanceSearch,
  loadColumns,
  findAsyouType,
  saveAdvanceSearch,
  deleteSavedQuery,
  getAdvanceSearchById,
  getAllAdvanceSearchDAta,
  gg1ReportDownload,
} from "../../_helper/services/advanceSearchv2";

function* onAdvanceSearch({ payload }) {
  try {
    const response = yield call(advanceSearch, payload);
    yield put(advanceSearchSuccess(response));
  } catch (error) {
    yield put(advanceSearchFail(error.response));
  }
}
function* onGG1ExcelReport({ payload }) {
  try {
    // debugger;
    const response = yield call(gg1ReportDownload, payload);
    yield put(gg1ExcelReportSuccess(response));
  } catch (error) {
    yield put(gg1ExcelReportFail(error.response));
  }
}
function* onSaveAdvanceSearch({ payload }) {
  try {
    const response = yield call(saveAdvanceSearch, payload);
    yield put(saveAdvanceSearchSuccess(response));
  } catch (error) {
    yield put(saveAdvanceSearchFail(error.response));
  }
}
function* onDeleteSavedQuery({ payload }) {
  try {
    const response = yield call(deleteSavedQuery, payload);
    yield put(deleteSearchSaveQuerySuccess(response));
  } catch (error) {
    yield put(deleteSearchSaveQueryFail(error.response));
  }
}

function* onGetAdvanceSearchById({ payload, params }) {
  try {
    const response = yield call(getAdvanceSearchById, payload, params);
    yield put(getAdvanceSearchByIdSuccess(response));
  } catch (error) {
    yield put(getAdvanceSearchByIdFail(error.response));
  }
}

function* onGetAllAdvanceSearchData({ payload }) {
  try {
    console.log("parameters CALLLING FIRSt");
    const response = yield call(getAllAdvanceSearchDAta, payload);
    console.log("parameters CALLLING SEC");

    yield put(getAllAdvanceSearchSuccess(response));
  } catch (error) {
    console.log("parameters CALLLING FIRSt");

    yield put(getAllAdvanceSearchFail(error.response));
  }
}

function* onLoadColumns({ payload: { tableName } }) {
  try {
    const response = yield call(loadColumns, tableName);
    yield put(loadColumnsSuccess(response));
  } catch (error) {
    yield put(loadColumnsFail(error.response));
  }
}

function* onFindAsYouType({
  payload: { table, keyField, valueField, search },
}) {
  try {
    const response = yield call(
      findAsyouType,
      table,
      keyField,
      valueField,
      search
    );
    yield put(findAsyouTypeSuccess(response));
  } catch (error) {
    yield put(findAsYouTypeFail(error.response));
  }
}
function* CartSaga() {
  yield takeLatest(ADVANCE_SEARCH, onAdvanceSearch);
  yield takeLatest(LOAD_COLUMNS, onLoadColumns);
  yield takeLatest(FINDASYOUTYPE, onFindAsYouType);
  yield takeLatest(SAVE_ADVANCE_SEARCH, onSaveAdvanceSearch);
  yield takeLatest(GET_ADVANCE_SEARCH_BYID, onGetAdvanceSearchById);
  yield takeLatest(GET_ALL_ADVANCE_SEARCH, onGetAllAdvanceSearchData);
  yield takeLatest(DELETE_SAVED_QUERY, onDeleteSavedQuery);
  yield takeLatest(GG1_EXCEL_REPORT, onGG1ExcelReport);
}

export default CartSaga;
