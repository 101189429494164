export const ADVANCE_SEARCH = "ADVANCE_SEARCH";
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCH_SUCCESS";
export const ADVANCE_SEARCH_FAIL = "ADVANCE_SEARCH_FAIL";
export const LOAD_COLUMNS = "ADVANCE_SEARCH_LOAD_COLUMNS";
export const LOAD_COLUMNS_SUCCESS = "ADVANCE_SEARCH_LOAD_COLUMNS_SUCCESS";
export const LOAD_COLUMNS_FAIL = "ADVANCE_SEARCH_LOAD_COLUMNS_FAIL";

export const GET_EMPLOYEE_DEDUCTION = "GET_EMPLOYEE_DEDUCTION";
export const GET_EMPLOYEE_DEDUCTION_SUCCESS = "GET_EMPLOYEE_DEDUCTION_SUCCESS";
export const GET_EMPLOYEE_DEDUCTION_FAIL = "GET_EMPLOYEE_DEDUCTION_FAIL";

export const GET_EMPLOYEE_TYPES = "GET_EMPLOYEE_TYPES";
export const GET_EMPLOYEE_TYPES_SUCCESS = "GET_EMPLOYEE_TYPES_SUCCESS";
export const GET_EMPLOYEE_TYPES_FAIL = "GET_EMPLOYEE_TYPES_FAIL";

export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";

export const SUBSCRIBE_DEDUCTION = "EMPLOYEE_SUBSCRIBE_DEDUCTION";
export const SUBSCRIBE_DEDUCTION_SUCCESS = "EMPLOYEE_SUBSCRIBE_DEDUCTION_SUCCESS";
export const SUBSCRIBE_DEDUCTION_FAIL = "EMPLOYEE_SUBSCRIBE_DEDUCTION_FAIL";

export const UNSUBSCRIBE_DEDUCTION = "EMPLOYEE_UNSUBSCRIBE_DEDUCTION";
export const UNSUBSCRIBE_DEDUCTION_SUCCESS = "EMPLOYEE_UNSUBSCRIBE_DEDUCTION_SUCCESS";
export const UNSUBSCRIBE_DEDUCTION_FAIL = "EMPLOYEE_UNSUBSCRIBE_DEDUCTION_FAIL";
export const FINDASYOUTYPE="FINDASYOUTYPE";
export const FINDASYOUTYPE_SUCCESS="FINDASYOUTYPE_SUCCESS";
export const FINDASYOUTYPE_FAIL="FINDASYOUTYPE_FAIL";
