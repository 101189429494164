import {
  SEARCH_EMPLOYEE,
  SEARCH_EMPLOYEE_SUCCESS,
  SEARCH_EMPLOYEE_FAIL,
  GET_EMPLOYEE_DEDUCTION,
  GET_EMPLOYEE_DEDUCTION_SUCCESS,
  GET_EMPLOYEE_DEDUCTION_FAIL,
  GET_EMPLOYEE_TYPES,
  GET_EMPLOYEE_TYPES_FAIL,
  GET_EMPLOYEE_TYPES_SUCCESS,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  FIND_EMPLOYEE,
  FIND_EMPLOYEE_SUCCESS,
  FIND_EMPLOYEE_FAIL,
  EMPLOYEE,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  SUBSCRIBE_DEDUCTION,
  SUBSCRIBE_DEDUCTION_SUCCESS,
  SUBSCRIBE_DEDUCTION_FAIL,
  UNSUBSCRIBE_DEDUCTION,
  UNSUBSCRIBE_DEDUCTION_SUCCESS,
  UNSUBSCRIBE_DEDUCTION_FAIL,
  GET_EMPLOYEE_TRANSACTIONLOGS,
  GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS,
  GET_EMPLOYEE_TRANSACTIONLOGS_FAIL,
  GET_EMPLOYEE_TRANSACTIONINFO,
  GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS,
  GET_EMPLOYEE_TRANSACTIONINFO_FAIL,
  AUDIT_TRAIL_HISTORY,
  AUDIT_TRAIL_HISTORY_SUCCESS,
  AUDIT_TRAIL_HISTORY_FAIL,
  UPDATE_DEDUCTION,
  UPDATE_DEDUCTION_SUCCESS,
  UPDATE_DEDUCTION_FAIL,
  UPDATE_CERTIFICATIONS,
  UPDATE_CERTIFICATIONS_SUCCESS,
  UPDATE_CERTIFICATIONS_FAIL,
  CREATE_CERTIFICATIONS,
  CREATE_CERTIFICATIONS_SUCCESS,
  CREATE_CERTIFICATIONS_FAIL,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  GET_DOCUMENT_ATTACHED,
  GET_DOCUMENT_ATTACHED_SUCCESS,
  GET_DOCUMENT_ATTACHED_FAIL,
  EMPLOYEE_LIST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_FAIL,
} from "./actionTypes";

export const getEmployeeTypes = () => {
  return {
    type: GET_EMPLOYEE_TYPES,
  };
};
export const getEmployeeTypesSuccess = (results) => {
  return {
    type: GET_EMPLOYEE_TYPES_SUCCESS,
    payload: results,
  };
};
export const getEmployeeTypesFails = (error) => {
  return {
    type: GET_EMPLOYEE_TYPES_FAIL,
    payload: error,
  };
};
export const searchEmployee = (parameters) => {
  return {
    type: SEARCH_EMPLOYEE,
    payload: parameters,
  };
};

export const searchEmployeeSuccess = (results) => {
  return {
    type: SEARCH_EMPLOYEE_SUCCESS,
    payload: results,
  };
};

export const searchEmployeeFail = (error) => {
  return {
    type: SEARCH_EMPLOYEE_FAIL,
    payload: error,
  };
};

export const getEmployeeDeduction = (id) => {
  return {
    type: GET_EMPLOYEE_DEDUCTION,
    payload: id,
  };
};

export const getEmployeeDeductionSuccess = (result) => {
  return {
    type: GET_EMPLOYEE_DEDUCTION_SUCCESS,
    payload: result,
  };
};

export const getEmployeeDeductionFail = (error) => {
  return {
    type: GET_EMPLOYEE_DEDUCTION_FAIL,
    payload: error,
  };
};
export const findOutEmployee = (id) => {
  return {
    type: FIND_EMPLOYEE,
    payload: id,
  };
};

export const findOutEmployeeSuccess = (result) => {
  return {
    type: FIND_EMPLOYEE_SUCCESS,
    payload: result,
  };
};

export const findOutEmployeeFail = (error) => {
  return {
    type: FIND_EMPLOYEE_FAIL,
    payload: error,
  };
};
export const auditTrail = (id) => {
  return {
    type: AUDIT_TRAIL_HISTORY,
    payload: id,
  };
};

export const auditTrailSuccess = (result) => {
  return {
    type: AUDIT_TRAIL_HISTORY_SUCCESS,
    payload: result,
  };
};

export const auditTrailFail = (error) => {
  return {
    type: AUDIT_TRAIL_HISTORY_FAIL,
    payload: error,
  };
};
export const Employee = (employeeID) => {
  return {
    type: EMPLOYEE,
    payload: employeeID,
  };
};
export const EmployeeSuccess = (result) => {
  return {
    type: EMPLOYEE_SUCCESS,
    payload: result,
  };
};
export const EmployeeFails = (error) => {
  return {
    type: EMPLOYEE_FAIL,
    payload: error,
  };
};

export const getEmployee = (employeeID) => {
  return {
    type: GET_EMPLOYEE,
    payload: employeeID,
  };
};
export const getEmployeeSuccess = (result) => {
  return {
    type: GET_EMPLOYEE_SUCCESS,
    payload: result,
  };
};
export const getEmployeeFails = (error) => {
  return {
    type: GET_EMPLOYEE_FAIL,
    payload: error,
  };
};
export const onCertificationCreation = (payload) => {
  return {
    type: CREATE_CERTIFICATIONS,
    payload,
  };
};
export const onCertificationCreationSuccess = (results) => {
  return {
    type: CREATE_CERTIFICATIONS_SUCCESS,
    payload: results,
  };
};
export const onCertificationCreationFails = (error) => {
  return {
    type: CREATE_CERTIFICATIONS_FAIL,
    payload: error,
  };
};
export const updateCertifications = (payload, id) => {
  return {
    type: UPDATE_CERTIFICATIONS,
    payload: payload,
    id: id,
  };
};
export const updateCertificationsSuccess = (results) => {
  return {
    type: UPDATE_CERTIFICATIONS_SUCCESS,
    payload: results,
  };
};
export const updateCertificationsFails = (error) => {
  return {
    type: UPDATE_CERTIFICATIONS_FAIL,
    payload: error,
  };
};
export const createTag = (payload) => {
  return {
    type: CREATE_TAG,
    payload,
  };
};
export const createTagSuccess = (results) => {
  return {
    type: CREATE_TAG_SUCCESS,
    payload: results,
  };
};
export const createTagFails = (error) => {
  return {
    type: CREATE_TAG_FAIL,
    payload: error,
  };
};
export const updateTag = (payload, id) => {
  return {
    type: UPDATE_TAG,
    payload: payload,
    id: id,
  };
};
export const updateTagSuccess = (results) => {
  return {
    type: UPDATE_TAG_SUCCESS,
    payload: results,
  };
};
export const updateTagFails = (error) => {
  return {
    type: UPDATE_TAG_FAIL,
    payload: error,
  };
};

export const getDocumentAttached = (payload) => {
  return {
    type: GET_DOCUMENT_ATTACHED,
    payload: payload,
  };
};
export const getDocumentAttachedSuccess = (results) => {
  return {
    type: GET_DOCUMENT_ATTACHED_SUCCESS,
    payload: results,
  };
};
export const getDocumentAttachedFail = (error) => {
  return {
    type: GET_DOCUMENT_ATTACHED_FAIL,
    payload: error,
  };
};

export const updateDeduction = (payload) => {
  return {
    type: UPDATE_DEDUCTION,
    payload,
  };
};
export const updateDeductionSuccess = (results) => {
  return {
    type: UPDATE_DEDUCTION_SUCCESS,
    payload: results,
  };
};
export const updateDeductionFails = (error) => {
  return {
    type: UPDATE_DEDUCTION_FAIL,
    payload: error,
  };
};

export const perfomDeductionSubscription = (payload) => {
  return {
    type: SUBSCRIBE_DEDUCTION,
    payload,
  };
};
export const perfomDeductionSubscriptionSuccess = (results) => {
  return {
    type: SUBSCRIBE_DEDUCTION_SUCCESS,
    payload: results,
  };
};
export const perfomDeductionSubscriptionFails = (error) => {
  return {
    type: SUBSCRIBE_DEDUCTION_FAIL,
    payload: error,
  };
};

export const perfomDeductionUnSubscription = (payload) => {
  return {
    type: UNSUBSCRIBE_DEDUCTION,
    payload,
  };
};
export const perfomDeductionUnSubscriptionSuccess = (results) => {
  return {
    type: UNSUBSCRIBE_DEDUCTION_SUCCESS,
    payload: results,
  };
};
export const perfomDeductionUnSubscriptionFails = (error) => {
  return {
    type: UNSUBSCRIBE_DEDUCTION_FAIL,
    payload: error,
  };
};

export const getEmployeeTransactionLogs = (employeeID) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONLOGS,
    payload: employeeID,
  };
};
export const getEmployeeTransactionLogsSuccess = (result) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONLOGS_SUCCESS,
    payload: result,
  };
};
export const getEmployeeTransactionLogsFails = (error) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONLOGS_FAIL,
    payload: error,
  };
};

export const getEmployeeTransactionInfo = (employeeID, logID) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONINFO,
    payload: { employeeID, logID },
  };
};
export const getEmployeeTransactionInfoSuccess = (result) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONINFO_SUCCESS,
    payload: result,
  };
};
export const getEmployeeTransactionInfoFails = (error) => {
  return {
    type: GET_EMPLOYEE_TRANSACTIONINFO_FAIL,
    payload: error,
  };
};

export const getEmployeeList = () => {
  return {
    type: EMPLOYEE_LIST,
  };
};
export const getEmployeeListSuccess = (results) => {
  return {
    type: EMPLOYEE_LIST_SUCCESS,
    payload: results,
  };
};
export const getEmployeeListFails = (error) => {
  return {
    type: EMPLOYEE_LIST_FAIL,
    payload: error,
  };
};