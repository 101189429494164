import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import SidebarMenuItems from "./SidebarMenuItems";
import NewSidebarMenuItems from "./NewSidebarMenuItems";
import { ArrowLeft, ArrowRight } from "react-feather";

const SidebarMenu = ({
  setMainMenu,
  props,
  sidebartoogle,
  setNavActive,
  width,
}) => {
  const [rightArrow, setRightArrow] = useState(false);
  const [leftArrow, setLeftArrow] = useState(false);
  const [margin, setMargin] = useState(0);
  // const [wrapper, setWrapper] = useState(null);

  const { storedTheme } = useSelector((state) => state?.ThemeCustomizerReducer);
  let wrapper = null;
  wrapper = storedTheme?.settings?.sidebar.type || "compact-wrapper";
  console.log("wrapper", wrapper);
  useEffect(() => {
    setLeftArrow(true);
  }, []);
  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      setRightArrow(true);
      setLeftArrow(false);
    } else {
      setLeftArrow(false);
      setMargin((margin) => (margin += -width));
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      setLeftArrow(true);
      setRightArrow(false);
    } else {
      setMargin((margin) => (margin += width));
      setRightArrow(false);
    }
  };

  return (
    <Fragment>
      <nav>
        <div className="main-navbar">
          <div
            className={`left-arrow ${leftArrow ? "d-none" : ""}`}
            id="left-arrow"
            onClick={scrollToLeft}
          >
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper !== null &&
              wrapper?.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <NewSidebarMenuItems
              setMainMenu={setMainMenu}
              props={props}
              sidebartoogle={sidebartoogle}
              setNavActive={setNavActive}
            />
          </div>
          <div
            className={`right-arrow ${rightArrow ? "d-none" : ""}`}
            onClick={scrollToRight}
          >
            <ArrowRight />
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default React.memo(SidebarMenu);
