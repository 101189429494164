// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getDepartmentDetails =(id)=> get(`${baseURL+url.DEPARTMENT_BASE}/${id}`);
export const getDepartments =({page,pageSize})=> get(baseURL+url.DEPARTMENT_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
// export const updateDepartment = (ID,payload) => put(url.DEPARTMENT_BASE+'/'+ID,payload);

export const updateDepartment = (ID, payload) => put(`${baseURL}${url.DEPARTMENT_BASE}/${ID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// export const updatePositioStatus = (ID,payload) => put(url.DEPARTMENT_BASE_UPDATE+'/'+ID, payload);
// export const updateDepartmentStatusList = (payload) => put(url.DEPARTMENT_BASE_UPDATE,payload);
export const createDepartment =(payload) => post(baseURL+url.DEPARTMENT_BASE,payload);
//Post
export const deleteDepartment = (ID) => remove(baseURL+url.DEPARTMENT_BASE+'/'+ID);


// import { get, post, remove, put } from "../api_helper";
// import * as url from "../url_helper";

// const baseURL = process.env.REACT_APP_API_DOTNET_URL;

// export const getDepartmentDetails = (id) => get(`${baseURL}${url.DEPARTMENT_BASE}/${id}`);
// export const getDepartments = ({ page, pageSize }) => get(`${baseURL}${url.DEPARTMENT_BASE}?pageNumber=${page}&pageSize=${pageSize}`);
// export const updateDepartment = (id, payload) => put(`${baseURL}${url.DEPARTMENT_BASE}/${id}`, payload);
// export const createDepartment = (payload) => post(`${baseURL}${url.DEPARTMENT_BASE}`, payload);
// export const deleteDepartment = (id) => remove(`${baseURL}${url.DEPARTMENT_BASE}/${id}`);
