
import { takeLatest, put, call } from "redux-saga/effects";

import {	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_Salary_Group,
 } from "./actionTypes";

import {
  getChartDataYearOfServiceSuccess,
  getChartDataYearOfServiceFail,
  getChartDataAgeGroupSuccess,
  getChartDataAgeGroupFail,
  getChartDataSalaryGroupSuccess,
  getChartDataSalaryGroupFail
} from "./actions";

import { getChartDataForYearOfService , getChartDataForAgeGroup , getChartDataForSalaryGroup }  from "../../_helper/services/chart"

function* onGetChartDataYearofService({ payload: queryName }) {
  try {
    const response = yield call(getChartDataForYearOfService,queryName);
    yield put(getChartDataYearOfServiceSuccess(response));
  } catch (error) {
    yield put(getChartDataYearOfServiceFail(error.response));
  }
}


function* onGetChartDataAgeGroup({ payload: queryName }) {
  try {
    const response = yield call(getChartDataForAgeGroup , queryName);
    yield put(getChartDataAgeGroupSuccess(response));
  } catch (error) {
    yield put(getChartDataAgeGroupFail(error.response));
  }
}

function* onGetChartDataSalaryGroup({ payload: queryName }) {
  try {
    const response = yield call(getChartDataForSalaryGroup , queryName);
    yield put(getChartDataSalaryGroupSuccess(response));
  } catch (error) {
    yield put(getChartDataSalaryGroupFail(error.response));
  }
}
function* ChartSaga() {
  yield takeLatest(GET_CHART_DATA_YEAR_SERVICE, onGetChartDataYearofService);
  yield takeLatest(GET_CHART_DATA_AGE_GROUP, onGetChartDataAgeGroup);
  yield takeLatest(GET_CHART_DATA_AGE_Salary_Group, onGetChartDataSalaryGroup);
}

export default ChartSaga;