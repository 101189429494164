import {
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_SUCCESS,
  ADVANCE_SEARCH_FAIL,
  LOAD_COLUMNS,
  LOAD_COLUMNS_SUCCESS,
  LOAD_COLUMNS_FAIL,
  FINDASYOUTYPE,
  FINDASYOUTYPE_SUCCESS,
  FINDASYOUTYPE_FAIL,
  SAVE_ADVANCE_SEARCH,
  SAVE_ADVANCE_SEARCH_SUCCESS,
  SAVE_ADVANCE_SEARCH_FAIL,
  GET_ADVANCE_SEARCH_BYID,
  GET_ADVANCE_SEARCH_BYID_SUCCESS,
  GET_ADVANCE_SEARCH_BYID_FAIL,
  GET_ALL_ADVANCE_SEARCH,
  GET_ALL_ADVANCE_SEARCH_SUCCESS,
  GET_ALL_ADVANCE_SEARCH_FAIL,
  SAVE_QUERY,
  SAVE_LOCATION,
  DELETE_SAVED_QUERY,
  DELETE_SAVED_QUERY_SUCCESS,
  DELETE_SAVED_QUERY_FAIL,
  GG1_EXCEL_REPORT,
  GG1_EXCEL_REPORT_SUCCESS,
  GG1_EXCEL_REPORT_FAIL,
} from "./actionTypes";

export const advanceSearch = (parameters) => {
  return {
    type: ADVANCE_SEARCH,
    payload: parameters,
  };
};

export const advanceSearchSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_SUCCESS,
    payload: results,
  };
};

export const advanceSearchFail = (error) => {
  return {
    type: ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};
export const SaveQuery = (parameters) => {
  return {
    type: SAVE_QUERY,
    payload: parameters,
  };
};
export const SaveLocation = (parameters) => {
  return {
    type: SAVE_LOCATION,
    payload: parameters,
  };
};

export const gg1ExcelReport = (payload) => {
  return {
    type: GG1_EXCEL_REPORT,
    payload,
  };
};
export const gg1ExcelReportSuccess = (results) => {
  return {
    type: GG1_EXCEL_REPORT_SUCCESS,
    payload: results,
  };
};
export const gg1ExcelReportFail = (error) => {
  return {
    type: GG1_EXCEL_REPORT_FAIL,
    payload: error,
  };
};

export const saveAdvanceSearch = (parameters) => {
  return {
    type: SAVE_ADVANCE_SEARCH,
    payload: parameters,
  };
};

export const saveAdvanceSearchSuccess = (results) => {
  return {
    type: SAVE_ADVANCE_SEARCH_SUCCESS,
    payload: results,
  };
};

export const saveAdvanceSearchFail = (error) => {
  return {
    type: SAVE_ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};
export const deleteSearchSaveQuery = (parameters) => {
  return {
    type: DELETE_SAVED_QUERY,
    payload: parameters,
  };
};

export const deleteSearchSaveQuerySuccess = (results) => {
  return {
    type: DELETE_SAVED_QUERY_SUCCESS,
    payload: results,
  };
};

export const deleteSearchSaveQueryFail = (error) => {
  return {
    type: DELETE_SAVED_QUERY_FAIL,
    payload: error,
  };
};
export const getAllAdvanceSearch = (parameters) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH,
    payload: parameters,
  };
};

export const getAllAdvanceSearchSuccess = (results) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH_SUCCESS,
    payload: results,
  };
};

export const getAllAdvanceSearchFail = (error) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};

export const getAdvanceSearchById = (parameters, params) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID,
    payload: parameters,
    params: params,
  };
};

export const getAdvanceSearchByIdSuccess = (results) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID_SUCCESS,
    payload: results,
  };
};

export const getAdvanceSearchByIdFail = (error) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID_FAIL,
    payload: error,
  };
};

export const loadColumns = (tableName) => {
  return {
    type: LOAD_COLUMNS,
    payload: { tableName },
  };
};

export const loadColumnsSuccess = (results) => {
  return {
    type: LOAD_COLUMNS_SUCCESS,
    payload: results,
  };
};

export const loadColumnsFail = (error) => {
  return {
    type: LOAD_COLUMNS_FAIL,
    payload: error,
  };
};

export const findAsyouType = (payload) => {
  return {
    type: FINDASYOUTYPE,
    payload,
  };
};
export const findAsyouTypeSuccess = (results) => {
  return {
    type: FINDASYOUTYPE_SUCCESS,
    payload: results,
  };
};
export const findAsYouTypeFail = (error) => {
  return {
    type: FINDASYOUTYPE_FAIL,
    payload: error,
  };
};
