import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPmrcsalariesDetails = ({ grade, step }) =>
  get(`${baseURL}${url.PMRCSALARIES_BASE}/${grade}/${step}`);

export const getPmrcsalariess = ({ page, pageSize }) =>
  get(`${baseURL}${url.PMRCSALARIES_BASE}?pageNumber=${page}&pageSize=${pageSize}`);

export const updatePmrcsalaries = ({ grade,  step }, payload) =>
  put(
    `${baseURL}${url.PMRCSALARIES_BASE}/${grade}/${step}`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const createPmrcsalaries = (payload) =>
  post(`${baseURL}${url.PMRCSALARIES_BASE}`, payload);


export const deletePmrcsalaries = ({ grade, step }) => remove(
  `${baseURL}${url.PMRCSALARIES_BASE}/${grade}/${step}`
);
