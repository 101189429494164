import { get, post, remove } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getDeleteDocument = (id) =>
  remove(baseURL + url.DELETE_DOCUMENT + "/" + id);

export const getDocumentRequiredFields = (id) =>
  get(`${baseURL + url.LkDocumentSource}/${id}`);
export const getDocumentSources = (id) =>
  get(`${baseURL + url.LkDocumentSource}`);
export const postDocumentsAPI = (payload) =>
  post(baseURL + url.postDocuments, payload);
