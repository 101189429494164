import { get, post, postReturnResponse, remove } from "../api_helper";
import * as url from "../url_helper";

const URL_AdvanceSearch = "/AdvanceSearch/[LOCATION]";
const GG1_EXCEL_ADVANCESEARCH = "/AdvanceSearch";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;
const BaseURL = process.env.REACT_APP_AZURE_URL;
//Post
export const gg1ReportDownload = (payload) => {
  const { fileExtension, ...rest } = payload;
  return postReturnResponse(
    baseURL +
      GG1_EXCEL_ADVANCESEARCH +
      `/CommonSearchExport?fileExtension=${fileExtension}`,
    rest,
    { responseType: "blob" }
  );
};

export const advanceSearch = (payload) => {
  if (payload.location === "npa") {
    return post(
      baseURL +
        URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
        `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&transType=NPA`,
      payload
    );
  } else {
    return post(
      baseURL +
        URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
        `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
      payload
    );
  }
};

export const loadColumns = (tableName) =>
  get(baseURL + url.ADVANCE_SEARCH_LOAD_COLUMNS + "=" + tableName);
export const findAsyouType = (table, keyField, valueField, search) =>
  get(
    url.ADVANCE_SEARCH_LOAD_FINDASTYPE +
      "/" +
      `${table}/${keyField}/${valueField}/${search}`
  );

export const saveAdvanceSearch = (payload) =>
  post(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    payload
  );
export const deleteSavedQuery = (payload) => {
  console.log("payload", payload);
  return remove(
    BaseURL + URL_AdvanceSearch.replace("[LOCATION]", "DeleteSearch"),
    payload
    //   `/${Number(qID)}`
    // payload
  );
};

export const getAdvanceSearchById = (payload, params) =>
  get(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    params
  );
export const getAllAdvanceSearchDAta = (payload) =>
  post(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    payload
  );
