export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAIL = "GET_ANNOUNCEMENTS_FAIL";
export const APPLICATION_CREATE_AND_UPDATE = "APPLICATION_CREATE_AND_UPDATE";
export const GET_JOB_APPLICATION_LEAD = "GET_JOB_APPLICATION_LEAD";
export const GET_JOB_APPLICATION_LEAD_SUCCESS =
  "GET_JOB_APPLICATION_LEAD_SUCCESS";
export const GET_JOB_APPLICATION_LEAD_FAIL = "GET_JOB_APPLICATION_LEAD_FAIL";
export const GET_ETHNICITIES = "GET_ETHNICITIES";
export const GET_ETHNICITIES_SUCCESS = "GET_ETHNICITIES_SUCCESS";
export const GET_ETHNICITIES_FAIL = "GET_ETHNICITIES_FAIL";
export const GET_PERSON_RACES = "GET_PERSON_RACES";
export const GET_PERSON_RACES_SUCCESS = "GET_PERSON_RACES_SUCCESS";
export const GET_PERSON_RACES_FAIL = "GET_PERSON_RACES";
export const GET_JOB_TYPES = "GET_JOB_TYPES";
export const GET_JOB_TYPES_SUCCESS = "GET_JOB_TYPES_SUCCESS";
export const GET_JOB_TYPES_FAIL = "GET_JOB_TYPES_FAIL";
export const GET_POSITION_TYPES = "GET_POSITION_TYPES";
export const GET_POSITION_TYPES_SUCCESS = "GET_POSITION_TYPES_SUCCESS";
export const GET_POSITION_TYPES_FAIL = "GET_POSITION_TYPES_FAIL";
export const GET_AGE_RANGES = "GET_AGE_RANGES";
export const GET_AGE_RANGES_SUCCESS = "GET_AGE_RANGES_SUCCESS";
export const GET_AGE_RANGES_FAIL = "GET_AGE_RANGES_FAIL";
export const GET_QUESTION_POSITIONS = "GET_QUESTION_POSITIONS";
export const GET_QUESTION_POSITIONS_SUCCESS = "GET_QUESTION_POSITIONS_SUCCESS";
export const GET_QUESTION_POSITIONS_FAIL = "GET_QUESTION_POSITIONS_FAIL";
export const GET_APPLICATION_EMP_AND_ANN = "GET_APPLICATION_EMP_AND_ANN";
export const GET_APPLICATION_EMP_AND_ANN_SUCCESS = "GET_APPLICATION_EMP_AND_ANN_SUCCESS";
export const GET_APPLICATION_EMP_AND_ANN_FAIL = "GET_APPLICATION_EMP_AND_ANN_FAIL";
export const GET_TERMINATION_REASONS = "GET_TERMINATION_REASONS";
export const GET_TERMINATION_REASONS_SUCCESS = "GET_TERMINATION_REASONS_SUCCESS";
export const GET_TERMINATION_REASONS_FAIL = "GET_TERMINATION_REASONS_FAIL";
export const GET_CITIZENSHIP = "GET_CITIZENSHIP";
export const GET_CITIZENSHIP_SUCCESS = "GET_CITIZENSHIP_SUCCESS";
export const GET_CITIZENSHIP_FAIL = "GET_CITIZENSHIP_FAIL";

export const CREATE_JOB_APPLICATION = "CREATE_JOB_APPLICATION";
export const CREATE_JOB_APPLICATION_SUCCESS = "CREATE_JOB_APPLICATION_SUCCESS";
export const CREATE_JOB_APPLICATION_FAIL = "CREATE_JOB_APPLICATION_FAIL";

export const SAVE_JOB_APPLICATION = "SAVE_JOB_APPLICATION";
export const SAVE_JOB_APPLICATION_SUCCESS = "SAVE_JOB_APPLICATION_SUCCESS";
export const SAVE_JOB_APPLICATION_FAIL = "SAVE_JOB_APPLICATION_FAIL";

export const GET_ANNOUNCEMENT = "GET_ANNOUNCEMENT";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";
export const GET_ANNOUNCEMENT_FAIL = "GET_ANNOUNCEMENT_FAIL";

//Upload file/image
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

export const GET_FILES = "GET_FILES";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAIL = "GET_FILES_FAIL";

export const DELETE_UPLOAD_FILE = "DELETE_UPLOAD_FILE";
export const DELETE_UPLOAD_FILE_SUCCESS = "DELETE_UPLOAD_FILE_SUCCESS";
export const DELETE_UPLOAD_FILE_FAIL = "DELETE_UPLOAD_FILE_FAIL";

export const SET_ERROR_STATE = "SET_ERROR_STATE";

export const RESET = "RESET";
