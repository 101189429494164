import {
	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
	GET_CHART_DATA_YEAR_SERVICE_FAIL,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_GROUP_SUCCESS,
	GET_CHART_DATA_AGE_GROUP_FAIL,
	GET_CHART_DATA_AGE_Salary_Group,
	GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
	GET_CHART_DATA_AGE_Salary_Group_FAIL,
} from "./actionTypes";

export const getChartDataYearOfService = (queryName) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE,
		payload: queryName
	};
};


export const getChartDataYearOfServiceSuccess = (data) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
		payload: data
	};
};

export const getChartDataYearOfServiceFail = (error) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE_FAIL,
		payload: error
	};
};

export const getChartDataAgeGroup = (queryName) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP,
		payload: queryName
	};
};

export const getChartDataAgeGroupSuccess = (data) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP_SUCCESS,
		payload: data
	};
};


export const getChartDataAgeGroupFail = (error) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP_FAIL,
		payload: error
	};
};


export const getChartDataSalaryGroup = (queryName) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group,
		payload: queryName
	};
};


export const getChartDataSalaryGroupSuccess = (data) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
		payload: data
	};
};

export const getChartDataSalaryGroupFail = (error) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group_FAIL,
		payload: error
	};
};