import {
    GET_AGE_RANGES,
    GET_JOB_APPLICATION_LEAD_SUCCESS,
    GET_ANNOUNCEMENTS,
    GET_ANNOUNCEMENTS_FAIL,
    GET_ANNOUNCEMENTS_SUCCESS,
    GET_APPLICATION_EMP_AND_ANN,
    GET_ETHNICITIES,
    GET_JOB_APPLICATION_LEAD,
    GET_JOB_TYPES,
    GET_PERSON_RACES,
    GET_POSITION_TYPES,
    GET_QUESTION_POSITIONS,
    GET_TERMINATION_REASONS,
    GET_JOB_APPLICATION_LEAD_FAIL,
    GET_ETHNICITIES_SUCCESS,
    GET_ETHNICITIES_FAIL,
    GET_PERSON_RACES_SUCCESS,
    GET_PERSON_RACES_FAIL,
    GET_JOB_TYPES_SUCCESS,
    GET_POSITION_TYPES_SUCCESS,
    GET_AGE_RANGES_SUCCESS,
    GET_QUESTION_POSITIONS_SUCCESS,
    GET_APPLICATION_EMP_AND_ANN_SUCCESS,
    GET_TERMINATION_REASONS_SUCCESS,
    GET_TERMINATION_REASONS_FAIL,
    GET_APPLICATION_EMP_AND_ANN_FAIL,
    GET_QUESTION_POSITIONS_FAIL,
    GET_AGE_RANGES_FAIL,
    GET_POSITION_TYPES_FAIL,
    GET_JOB_TYPES_FAIL,
    CREATE_JOB_APPLICATION,
    CREATE_JOB_APPLICATION_SUCCESS,
    CREATE_JOB_APPLICATION_FAIL,
    GET_CITIZENSHIP,
    GET_CITIZENSHIP_SUCCESS,
    GET_CITIZENSHIP_FAIL,
    GET_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_FAIL,
    GET_ANNOUNCEMENT_SUCCESS,
    RESET,
    SET_ERROR_STATE,
    SAVE_JOB_APPLICATION_SUCCESS,
    SAVE_JOB_APPLICATION_FAIL,
    SAVE_JOB_APPLICATION,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAIL,
    DELETE_UPLOAD_FILE,
    DELETE_UPLOAD_FILE_SUCCESS,
    DELETE_UPLOAD_FILE_FAIL, GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAIL
} from "./actionTypes";

export const getAnnouncements = (payload) => {
    return {
        type: GET_ANNOUNCEMENTS,
        payload: payload
    };
};

export const getAppointmentsSuccess = (payload) => {
    return {
        type: GET_ANNOUNCEMENTS_SUCCESS,
        payload: payload
    };
};

export const getAppointmentsFail = (payload) => {
    return {
        type: GET_ANNOUNCEMENTS_FAIL,
        payload: payload
    };
};

export const getJobApplicationLeads = (payload) => {
    return {
        type: GET_JOB_APPLICATION_LEAD,
        payload: payload
    };
};

export const getJobApplicationLeadsSuccess = (payload) => {
    return {
        type: GET_JOB_APPLICATION_LEAD_SUCCESS,
        payload: payload
    };
};

export const getJobApplicationLeadsFail = (payload) => {
    return {
        type: GET_JOB_APPLICATION_LEAD_FAIL,
        payload: payload
    };
};

export const getEthnicities = (payload) => {
    return {
        type: GET_ETHNICITIES,
        payload: payload
    };
};

export const getEthnicitiesSuccess = (payload) => {
    return {
        type: GET_ETHNICITIES_SUCCESS,
        payload: payload
    };
};

export const getEthnicitiesFail = (payload) => {
    return {
        type: GET_ETHNICITIES_FAIL,
        payload: payload
    };
};

export const getPersonRaces = (payload) => {
    return {
        type: GET_PERSON_RACES,
        payload: payload
    };
};

export const getPersonRacesSuccess = (payload) => {
    return {
        type: GET_PERSON_RACES_SUCCESS,
        payload: payload
    };
};

export const getPersonRacesFail = (payload) => {
    return {
        type: GET_PERSON_RACES_FAIL,
        payload: payload
    };
};

export const getJobTypes = (payload) => {
    return {
        type: GET_JOB_TYPES,
        payload: payload
    };
};

export const getJobTypesSuccess = (payload) => {
    return {
        type: GET_JOB_TYPES_SUCCESS,
        payload: payload
    };
};

export const getJobTypesFail = (payload) => {
    return {
        type: GET_JOB_TYPES_FAIL,
        payload: payload
    };
};

export const getPositionTypes = (payload) => {
    return {
        type: GET_POSITION_TYPES,
        payload: payload
    };
};

export const getPositionTypesSuccess = (payload) => {
    return {
        type: GET_POSITION_TYPES_SUCCESS,
        payload: payload
    };
};

export const getPositionTypesFail = (payload) => {
    return {
        type: GET_POSITION_TYPES_FAIL,
        payload: payload
    };
};

export const getAgeRanges = (payload) => {
    return {
        type: GET_AGE_RANGES,
        payload: payload
    };
};

export const getAgeRangesSuccess = (payload) => {
    return {
        type: GET_AGE_RANGES_SUCCESS,
        payload: payload
    };
};

export const getAgeRangesFail = (payload) => {
    return {
        type: GET_AGE_RANGES_FAIL,
        payload: payload
    };
};

export const getQuestionsPositions = (payload) => {
    return {
        type: GET_QUESTION_POSITIONS,
        payload: payload
    };
};

export const getQuestionsPositionsSuccess = (payload) => {
    return {
        type: GET_QUESTION_POSITIONS_SUCCESS,
        payload: payload
    };
};

export const getQuestionsPositionsFail = (payload) => {
    return {
        type: GET_QUESTION_POSITIONS_FAIL,
        payload: payload
    };
};


export const getApplicationByEmployeeAnnouncement = (payload) => {
    return {
        type: GET_APPLICATION_EMP_AND_ANN,
        payload: payload
    };
};

export const getApplicationByEmployeeAnnouncementSuccess = (payload) => {
    return {
        type: GET_APPLICATION_EMP_AND_ANN_SUCCESS,
        payload: payload
    };
};

export const getApplicationByEmployeeAnnouncementFail = (payload) => {
    return {
        type: GET_APPLICATION_EMP_AND_ANN_FAIL,
        payload: payload
    };
};

export const getTerminationReasons = (payload) => {
    return {
        type: GET_TERMINATION_REASONS,
        payload: payload
    };
};

export const getTerminationReasonsSuccess = (payload) => {
    return {
        type: GET_TERMINATION_REASONS_SUCCESS,
        payload: payload
    };
};

export const getTerminationReasonsFail = (payload) => {
    return {
        type: GET_TERMINATION_REASONS_FAIL,
        payload: payload
    };
};

export const createJobApplication = (payload) => {
    return {
        type: CREATE_JOB_APPLICATION,
        payload: payload
    }
};

export const createJobApplicationSuccess = (response) => {
    return {
        type: CREATE_JOB_APPLICATION_SUCCESS,
        payload: response
    }
};

export const createJobApplicationFail = (error) => {
    return {
        type: CREATE_JOB_APPLICATION_FAIL,
        payload: error
    }
};

export const saveJobApplication = (payload) => {
    return {
        type: SAVE_JOB_APPLICATION,
        payload: payload
    }
};

export const saveJobApplicationSuccess = (response) => {
    return {
        type: SAVE_JOB_APPLICATION_SUCCESS,
        payload: response
    }
};

export const saveJobApplicationFail = (error) => {
    return {
        type: SAVE_JOB_APPLICATION_FAIL,
        payload: error
    }
};

export const getCitizenship = (payload) => {
    return {
        type: GET_CITIZENSHIP,
        payload: payload
    };
};

export const getCitizenshipSuccess = (payload) => {
    return {
        type: GET_CITIZENSHIP_SUCCESS,
        payload: payload
    };
};

export const getCitizenshipFail = (payload) => {
    return {
        type: GET_CITIZENSHIP_FAIL,
        payload: payload
    };
};

export const getAnnouncement = (payload) => {
    return {
        type: GET_ANNOUNCEMENT,
        payload: payload
    }
};

export const getAnnouncementSuccess = (payload) => {
    return {
        type: GET_ANNOUNCEMENT_SUCCESS,
        payload: payload
    }
};


export const getAnnouncementFail = (payload) => {
    return {
        type: GET_ANNOUNCEMENT_FAIL,
        payload: payload
    }
};

export const setOnlineJobApplicationError = (payload) => {
    return {
        type: SET_ERROR_STATE,
        payload: payload
    }
}

export const uploadFile = (payload) => {
    return {
        type: UPLOAD_FILE,
        payload: payload
    }
};

export const uploadFileSuccess = (response) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        payload: response
    }
};

export const uploadFileFail = (error) => {
    return {
        type: UPLOAD_FILE_FAIL,
        payload: error
    }
};

export const getFiles = (payload) => {
    return {
        type: GET_FILES,
        payload: payload
    }
};

export const getFilesSuccess = (response) => {
    return {
        type: GET_FILES_SUCCESS,
        payload: response
    }
};

export const getFilesFail = (error) => {
    return {
        type: GET_FILES_FAIL,
        payload: error
    }
};

export const deleteUploadFile = (payload) => {
    return {
        type: DELETE_UPLOAD_FILE,
        payload: payload
    }
};

export const deleteUploadFileSuccess = (response) => {
    return {
        type: DELETE_UPLOAD_FILE_SUCCESS,
        payload: response
    }
};

export const deleteUploadFileFail = (error) => {
    return {
        type: DELETE_UPLOAD_FILE_FAIL,
        payload: error
    }
};

export const reset = () => {
    return {
        type: RESET,

    };
};