import {
  ADVANCE_ADDITIONALWHERE,
  GRID_DATASTATE,
  GRID_FILTER,
  GRID_PAGE_SIZE,
  GRID_SKIP,
  GRID_SORT,
} from "./actionTypes";

const initialState = {
  filter: {},
  pageSize: {},
  additionalWhere: {},
  skip: {},
  sort: [],
  dataState: {},
};

const CommonReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GRID_FILTER: {
      const fltr = Object.assign(state.filter, action.payload);
      state = { ...state, filter: fltr };
      break;
    }
    case GRID_DATASTATE: {
      const dataState = Object.assign(state.dataState, action.payload);
      state = { ...state, dataState: dataState };
      break;
    }
    case ADVANCE_ADDITIONALWHERE: {
      const additionalWhere = Object.assign(
        state.additionalWhere,
        action.payload
      );
      state = { ...state, additionalWhere: additionalWhere };
      break;
    }
    case GRID_SKIP: {
      const skip = Object.assign(state.skip, action.payload);
      state = { ...state, skip: skip };
      break;
    }
    case GRID_SORT: {
      const sort = Object.assign(state.sort, action.payload);
      state = { ...state, sort: sort };
      break;
    }
    case GRID_PAGE_SIZE: {
      const pageSize = Object.assign(state.pageSize, action.payload);
      state = { ...state, pageSize: pageSize };
      break;
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CommonReducer;
